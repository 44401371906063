import React from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import "react-rater/lib/react-rater.css";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { postRequest } from "../../utils/request";
import toast from "react-hot-toast";
import { useState } from "react";
function AddTestimonial() {
  const [video, setVideo] = useState({
    duration: 0,
    size: 0,
  });
  const [file, setFile] = useState({});
  const { lang } = useLang();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  async function addBlog(inc) {
    console.log(inc);
    let f = {
      ...inc, 
      file: file,
      link: inc.link,
      duration_file: video?.duration,
      size_file: Math.round(video?.size / 1024 / 1024, 1),
    };
    const formData = new FormData();
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest("admin/blogs", formData, dispatch);
    if (result.data) {
      toast.success("Blog created successfully!");
      navigate("/blogs");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addBlog(dataIn);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      // Get video duration using HTML5 video element
      const video = document.getElementById("video");
      video.src = URL.createObjectURL(file);
      video.onloadedmetadata = () => {
        setVideo((prevInfo) => ({
          ...prevInfo,
          duration: video.duration,
        }));
      };

      // Get video size
      const sizeInBytes = file.size;
      setVideo((prevInfo) => ({
        ...prevInfo,
        size: sizeInBytes,
      }));
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-[50px]">
      <h1 className="text-[40px]">{lang.blog}</h1>
      <div className="grid gap-4 my-[30px]">
        <input
          type="text"
          placeholder={lang.paste_link}
          {...register("link")}
          className="p-[10px] bg-white border border-black rounded"
        />
        <span>{lang.or}</span>
        {/* <input
          type="text"
          placeholder="Вставить ответ"
        /> */}
        <div className="p-[10px] bg-white border border-black rounded">
          <label
            htmlFor="file"
            className="cursor-pointer flex justify-between items-center"
          >
            <input
              type="file"
              id="file"
              className="hidden"
              placeholder="Вставить ответ"
              accept="video/*"
              // {...register("file")}
              onChange={handleFileChange}
            />
            <span>{lang.select_file}</span>
            <span className="bg-[#038FF7] text-[#fff] p-[10px] px-[15px] rounded border border-black">
              {lang.upload}
            </span>
          </label>
        </div>
        <input
          type="text"
          required
          placeholder={lang.title}
          className="p-[10px] bg-white border border-black rounded"
          {...register("title_ru")}
        />
        <input
          type="text"
          required
          placeholder={`${lang.title}_en`}
          className="p-[10px] bg-white border border-black rounded"
          {...register("title_en")}
        />
        <textarea
          cols="30"
          rows="10"
          required
          placeholder="description_ru:"
          className="p-[5px] rounded outline-none border border-black"
          {...register("description_ru")}
        ></textarea>
        <textarea
          cols="30"
          rows="10"
          required
          placeholder="description_en:"
          className="p-[5px] rounded outline-none border border-black"
          {...register("description_en")}
        ></textarea>
        <input
          required
          type="text"
          placeholder={lang.seo_title}
          className="p-[10px] bg-white border border-black rounded"
          {...register("seo_title")}
        />
        <input
          required
          type="text"
          placeholder={lang.seo_description}
          className="p-[10px] bg-white border border-black rounded"
          {...register("seo_description")}
        />

        <input
          required
          type="text"
          placeholder={lang.seo_keywords}
          className="p-[10px] bg-white border border-black rounded"
          {...register("seo_keywords")}
        />
        <input
          required
          type="text"
          placeholder={lang.seo_tag}
          className="p-[10px] bg-white border border-black rounded"
          {...register("seo_tag")}
        />
        <div className="flex flex-col bg-white shadow-xl rounded-lg overflow-hidden max-w-[400px]">
          <video
            controls
            src="https://picsum.photos/900/?blur"
            className="w-full h-[200px]"
            id="video"
          ></video>
          <div className="p-[20px] text-[20px]">
            <div className="flex justify-between">
              <span>{lang.duration}:</span>
              <span className="opacity-50">
                {Number(video?.duration).toFixed()} sec.
              </span>
            </div>
            <div className="flex justify-between">
              <span>{lang.update_date}:</span>
              <span className="opacity-50">
                {new Date().toLocaleDateString()}
              </span>
            </div>
            <div className="flex justify-between">
              <span>{lang.file_size}:</span>
              <span className="opacity-50">
                {Math.round(video?.size / 1024 / 1024, 1)}МБ
              </span>
            </div>
          </div>
        </div>
        <BlueBtn text={lang.create} className={"max-w-[200px]"} type="submit" />
      </div>
    </form>
  );
}

export default AddTestimonial;
