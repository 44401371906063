import React, { useEffect, useState } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageUpload from "../../ui_components/UplodaImage";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getRequest, postRequest } from "../../utils/request";
import toast from "react-hot-toast";
function UpdateCategory() {
  const { id } = useParams();
  const [value, setValue] = useState("");
  const [valueEn, setValueEn] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState(false);
  const navigate = useNavigate();
  async function getData() {
    const result = await getRequest("admin/categories/" + id, dispatch);
    setData(result?.data);
    setValue(result?.data?.description_ru);
    setValueEn(result?.data?.description_en);
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { lang } = useLang();
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      ...data,
    },
  });
  async function updateClient(inc) {
    const formData = new FormData();
    const ru = value;
    const en = valueEn;

    let f = {
      ...inc,
      description_ru: ru,
      description_en: en,
      slug: inc.slug,
      except_ru: inc.except_ru,
      except_en: inc.except_en,
      popular_category: inc.popular_category ? "1" : "0",
      popular_asist: inc.popular_asist ? "1" : "0",
      icon: inc.icon,
      photo: inc.photo,
    };
    console.log(f);
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest(
      "admin/categoryUpdate/" + id,
      formData,
      dispatch
    );
    console.log(result);
    if (result.data) {
      toast.success("has been updated successfully");
      navigate("/categories");
    } else {
      toast.error("failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    console.log(dataIn);
    updateClient(dataIn);
  };
  return (
    data && (
      <div className="p-[50px]">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-2 items-start gap-[40px]"
        >
          <div className="grid gap-6 w-[100%] items-start">
            <h1 className="text-[24px]">{lang.add} Category</h1>
            <input
              required
              type="text"
              placeholder="Slug:"
              className="p-[10px] bg-white border border-black rounded"
              {...register("slug")}
              defaultValue={data?.slug}
            />
            <input
              required
              type="text"
              placeholder="Name_ru:"
              className="p-[10px] bg-white border border-black rounded"
              {...register("name_ru")}
              defaultValue={data?.name_ru}
            />
            <input
              required
              type="text"
              placeholder="Name_en:"
              className="p-[10px] bg-white border border-black rounded"
              {...register("name_en")}
              defaultValue={data?.name_en}
            />
            <ReactQuill
              theme="snow"
              placeholder="Description_ru"
              value={value}
              onChange={(e) => setValue(e)}
              className="bg-white  min-h-[300px]"
            />
            <ReactQuill
              theme="snow"
              placeholder="Description_en"
              value={valueEn}
              onChange={(e) => setValueEn(e)}
              className="bg-white  min-h-[300px]"
            />
            <textarea
              name=""
              placeholder={`${lang.except}_ru`}
              className="p-[10px] bg-white border border-black rounded"
              id=""
              cols="30"
              rows="10"
              {...register("except_ru")}
              defaultValue={data?.except_ru}
            ></textarea>
            <textarea
              name=""
              placeholder={`${lang.except}_en`}
              className="p-[10px] bg-white border border-black rounded"
              id=""
              cols="30"
              rows="10"
              {...register("except_en")}
              defaultValue={data?.except_en}
            ></textarea>
            <BlueBtn text={lang.add} type="submit" className={"w-[200px]"} />
          </div>
          <div className="flex flex-col gap-3">
            <div className="border rounded-[15px] border-black bg-white overflow-hidden">
              <h1 className="p-[10px] border-b border-black text-white bg-[#038FF7]">
                {lang.post_details}
              </h1>
              <div className="p-[20px] grid gap-3">
                <label htmlFor="" className="flex flex-col">
                  {lang.status}
                  <select
                    {...register("status")}
                    defaultValue={data?.status}
                    className="p-[5px] rounded outline-none border border-black cursor-pointer"
                  >
                    <option value="0">Publish</option>
                    <option value="1">Block</option>
                    <option value="2">Unblock</option>
                  </select>
                </label>

                <div className="switch-wrapper flex gap-3 mt-[20px]">
                  <label className="switch">
                    <input type="checkbox" {...register("popular_category")} />
                    <span className="slider round"></span>
                  </label>
                  <p>Popular category</p>
                </div>
                <div className="switch-wrapper flex gap-3 mt-[10px]">
                  <label className="switch">
                    <input type="checkbox" {...register("popular_asist")} />
                    <span className="slider round"></span>
                  </label>
                  <p>Popular assist</p>
                </div>
              </div>
            </div>
            <div className="border rounded-[15px] border-black bg-white overflow-hidden">
              <h1 className="p-[10px] border-b border-black text-white bg-[#038FF7]">
                {lang.testimonial_details}
              </h1>
              <div className="p-[20px] grid gap-3">
                <ImageUpload
                  label={"Choose image"}
                  name="photo"
                  control={control}
                />
                <ImageUpload name="icon" control={control} />
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  );
}

export default UpdateCategory;
