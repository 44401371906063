import React, { useEffect } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import "react-rater/lib/react-rater.css";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getRequest, postRequest } from "../../utils/request";
import toast from "react-hot-toast";
import { useState } from "react";
function AddTestimonial() {
  const { lang, langType } = useLang();
  const [data, setData] = useState([]);
  async function getData() {
    const result = await getRequest("admin/plans", dispatch);
    setData(result.data);
  }
  useEffect(() => {
    getData();
// eslint-disable-next-line react-hooks/exhaustive-deps 
 }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  async function addFeedback(inc) {
    const result = await postRequest("admin/coupons", inc, dispatch);
    if (result.data) {
      toast.success("Testimonial created successfully!");
      navigate("/cupons");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addFeedback(dataIn);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-6 w-[100%] items-start">
        <div className="p-[50px]">
          <h1 className="text-[40px]">{lang.create}</h1>
          <div className="grid gap-3 my-[40px]">
            <input
              type="text"
              required
              placeholder={`${lang.title_coupon}_ru`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("name_ru")}
            />
            <input
              type="text"
              required
              placeholder={`${lang.title_coupon}_en`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("name_en")}
            />

            <select
              className="p-[10px] bg-white border border-black rounded"
              {...register("plan_id")}
            >
              {data?.map((item) => (
                <option value={item.id}> {item[`name_${langType}`]}</option>
              ))}
            </select>
            <input
              type="number"
              placeholder={lang.size_discount}
              className="p-[10px] bg-white border border-black rounded"
              {...register("discount")}
            />
          </div>

          <BlueBtn type="submit" text={lang.create} className={"mt-[30px]"} />
        </div>
      </div>
    </form>
  );
}

export default AddTestimonial;
