import React, { useState, useEffect } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import ImageUpload from "../../ui_components/UplodaImage";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getRequest, postRequest } from "../../utils/request";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
function UpdateSubCategory() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [allCategories, setAllCategories] = useState([]);
  const [data, setData] = useState(false);
  const navigate = useNavigate();
  const { lang } = useLang();

  async function getCategories() {
    const result = await getRequest("admin/categories", dispatch);
    setAllCategories(result.data);
  }
  async function getData() {
    const result = await getRequest("admin/sub_categories/" + id, dispatch);
    setData(result?.data);
  }
  useEffect(() => {
    getCategories();
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateSub(inc) {
    let f = {
      ...inc,
      disable_comments: inc.disable_comments ? 1 : 0,
      featured: inc.featured ? 1 : 0,
    };
    const formData = new FormData();
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest(
      "admin/subCategoryUpdate/" + id,
      formData,
      dispatch
    );
    console.log(result);
    if (result.data) {
      toast.success("Category created successfully!");
      navigate("/subcategories");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    updateSub(dataIn);
  };
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      ...data,
    },
  });
  return (
    data && (
      <div className="p-[50px]">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-2 items-start gap-[40px]"
        >
          <div className="grid gap-6 w-[100%] items-start">
            <h1 className="text-[24px]">{lang.edit}</h1>
            <input
              required
              type="text"
              placeholder="Title_H1_ru:"
              className="p-[5px] rounded outline-none border border-black"
              {...register("title_ru")}
              defaultValue={data?.title_ru}
            />
            <input
              required
              type="text"
              placeholder="Title_H1_en:"
              className="p-[5px] rounded outline-none border border-black"
              {...register("title_en")}
              defaultValue={data?.title_en}
            />
            <input
              required
              type="text"
              placeholder="Title_h2_ru:"
              className="p-[5px] rounded outline-none border border-black"
              {...register("name_ru")}
              defaultValue={data?.name_ru}
            />
            <input
              required
              type="text"
              placeholder="Title_h2_en:"
              className="p-[5px] rounded outline-none border border-black"
              {...register("name_en")}
              defaultValue={data?.name_en}
            />

            <textarea
              cols="30"
              rows="10"
              required
              placeholder="description_ru:"
              className="p-[5px] rounded outline-none border border-black"
              {...register("description_ru")}
              defaultValue={data?.description_ru}
            ></textarea>
            <textarea
              cols="30"
              rows="10"
              required
              placeholder="description_en:"
              className="p-[5px] rounded outline-none border border-black"
              {...register("description_en")}
              defaultValue={data?.description_en}
            ></textarea>
            <input
              required
              type="text"
              placeholder={lang.seo_title}
              className="p-[10px] bg-white border border-black rounded"
              {...register("seo_title")}
              defaultValue={data?.seo_title}
            />
            <input
              required
              type="text"
              placeholder={lang.seo_description}
              className="p-[10px] bg-white border border-black rounded"
              {...register("seo_description")}
              defaultValue={data?.seo_description}
            />

            <input
              required
              type="text"
              placeholder={lang.seo_keywords}
              className="p-[10px] bg-white border border-black rounded"
              {...register("seo_keywords")}
              defaultValue={data?.seo_keywords}
            />
            <input
              required
              type="text"
              placeholder={lang.seo_tag}
              className="p-[10px] bg-white border border-black rounded"
              {...register("seo_tag")}
              defaultValue={data?.seo_tag}
            />
          </div>
          <div className="flex flex-col gap-3">
            <div className="border rounded-[15px] border-black bg-white overflow-hidden">
              <h1 className="p-[10px] border-b border-black text-white bg-[#038FF7]">
                {lang.post_details}
              </h1>
              <div className="p-[20px] grid gap-3">
                <label htmlFor="" className="flex flex-col">
                  Category
                  <select
                    className="p-[5px] rounded outline-none border border-black cursor-pointer"
                    {...register("category_id")}
                    defaultValue={data?.category_id}
                  >
                    {allCategories?.map((item, index) => (
                      <option value={item?.id} key={index}>
                        {item?.name_en}
                      </option>
                    ))}
                  </select>
                </label>
                <label htmlFor="" className="flex flex-col">
                  {lang.status}
                  <select
                    {...register("status")}
                    defaultValue={data?.status}
                    className="p-[5px] rounded outline-none border border-black cursor-pointer"
                  >
                    <option value="0">Publish</option>
                    <option value="1">Block</option>
                    <option value="2">Unblock</option>
                  </select>
                </label>

                <div className="switch-wrapper flex gap-3 mt-[20px]">
                  <label className="switch">
                    <input type="checkbox" {...register("disable_comments")} />
                    <span className="slider round"></span>
                  </label>
                  <p>{lang.disable_comments}</p>
                </div>
                <div className="switch-wrapper flex gap-3 mt-[10px]">
                  <label className="switch">
                    <input type="checkbox" {...register("featured")} />
                    <span className="slider round"></span>
                  </label>
                  <p>{lang.featured}</p>
                </div>
              </div>
            </div>
            <div className="border rounded-[15px] border-black bg-white overflow-hidden">
              <h1 className="p-[10px] border-b border-black text-white bg-[#038FF7]">
                {lang.testimonial_details}
              </h1>
              <div className="p-[20px] grid gap-3">
                <ImageUpload
                  label={"Choose image"}
                  name="photo"
                  control={control}
                />
                <ImageUpload name="icon" control={control} />
              </div>
            </div>

            <BlueBtn
              text={lang.publish}
              type="submit"
              className={"w-[200px]"}
            />
          </div>
        </form>
      </div>
    )
  );
}

export default UpdateSubCategory;
