import React, { useState, useEffect } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import { useLang } from "../../redux/selectors";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, putRequest } from "../../utils/request";

function UpdateCoupon() {
  const { id } = useParams();
  const [data, setData] = useState(false);
  const [tarifs, setTariffs] = useState([]);
  async function getData() {
    const result = await getRequest("admin/coupons/" + id, dispatch);
    const resultPlans = await getRequest("admin/plans", dispatch);
    setData(result?.data);
    setTariffs(resultPlans?.data);
  }
  useEffect(() => {
    getData();
// eslint-disable-next-line react-hooks/exhaustive-deps 
 }, []);
  const { lang, langType } = useLang();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ...data,
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function addTariff(inc) {
    const result = await putRequest("admin/coupons/" + id, inc, dispatch);
    console.log(result);
    if (result.data) {
      toast.success("coupon edited successfully!");
      navigate("/cupons");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addTariff(dataIn);
  };
  return (  
    data && (
      <form onSubmit={handleSubmit(onSubmit)} className="p-[50px]">
        <h1 className="text-[40px]">{lang.edit}</h1>
        <div className="w-[50%] grid gap-[20px] mt-[30px]">
          <div className="grid gap-3 my-[40px]">
            <input
              type="text"
              required
              placeholder={`${lang.title_coupon}_ru`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("name_ru")}
              defaultValue={data?.name_ru}
            />
            <input
              type="text"
              required
              placeholder={`${lang.title_coupon}_en`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("name_en")}
              defaultValue={data?.name_en}
            />

            <select
              className="p-[10px] bg-white border border-black rounded"
              {...register("plan_id")}
              defaultValue={data?.plan_id}
            >
              {tarifs.map((item) => (
                <option value={item.id}> {item[`name_${langType}`]}</option>
              ))}
            </select>
            <input
              type="number"
              placeholder={lang.size_discount}
              defaultValue={data?.discount}
              className="p-[10px] bg-white border border-black rounded"
              {...register("discount")}
            />
          </div>
          <BlueBtn text={lang.edit} type="submit" />
        </div>
      </form>
    )
  );
}

export default UpdateCoupon;
