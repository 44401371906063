import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { useAssistans } from './redux/selectors'
import { Toaster } from 'react-hot-toast'

function App({ router }) {
  const { isLoader } = useAssistans()
  return (
    <>
      {
        isLoader && <div className="loader-wrapper">
          <span className="loader"></span>
        </div>
      }
      <Toaster />
      <RouterProvider router={router} />
    </>
  )
}

export default App