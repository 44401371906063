import React from "react";
import { useForm } from "react-hook-form";
import {  postRequest } from "../utils/request";
import { useDispatch } from "react-redux";
import { authChange, setUser } from "../redux/slices/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from 'js-cookie';

const Login = () => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    const result = await postRequest("auth/login", data, dispatch);
    if (result.success) {
      toast.success(result.message);
      dispatch(authChange(true));
      dispatch(setUser(result));
      localStorage.setItem("token", result.token);
      localStorage.setItem("cookie", Cookies.get('laravel_session'));
      navigate("/");
      console.log(Cookies.get('laravel_session'));
      
    } else {
      toast.error("Login failed try again");
    }
  };
  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-md w-[40%]">
        <h2 className="text-2xl font-bold mb-4">Login</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Username
            </label>
            <input
              required
              type="text"
              className="w-full border rounded py-2 px-3 outline-none focus:border-blue-500"
              {...register("email")}
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Password
            </label>
            <input
              required
              type="password"
              className="w-full border rounded py-2 px-3 outline-none focus:border-blue-500"
              {...register("password")}
            />
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
