import React, { useEffect, useState } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import { Link } from "react-router-dom";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { deleteRequest, getRequest } from "../../utils/request";
import toast from "react-hot-toast";
function Cupons() {
  const { lang, langType } = useLang();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  async function getData() {
    const result = await getRequest("admin/coupons", dispatch);
    setData(result.data);
    console.log(result.data, "----------->", langType);
  }
  async function deleteCategory(id) {
    const result = await deleteRequest("admin/coupons/" + id, dispatch);
    console.log(result);
    if (result.data) {
      toast.success("cupon has been deleted successfully");
      getData();
    } else {
      toast.error("Operation failed try again");
    }
  }
  useEffect(() => {
    getData();
// eslint-disable-next-line react-hooks/exhaustive-deps 
 }, []);
  return (
    <div className="p-[30px] px-[60px]">
      <h1 className="text-[32px] font-[500] mb-[30px]">{lang.coupons}</h1>
      <Link to={"/create-cupon"}>
        <BlueBtn text={lang.create} className={"min-w-[200px]"} />
      </Link>
      <br />
      <p className="text-[20px]">
        {lang.on_page}: {data?.length}
      </p>
      {/* <p className="text-[20px]">{lang.displayed}: 10 / 2602</p> */}

      <table className="border-collapse w-full border border-black text-center rounded-md my-[40px]">
        <tr>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            №
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.title}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.tariff}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.discount}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.contorls}
          </td>
        </tr>
        {data?.map((item, i) => {
          return (
            <tr className={(i + 10) % 2 === 0 && "gray-row"} key={i}>
              <td className="p-[4px] border border-black bg-white">{i + 1}</td>
              <td className="p-[4px] border border-black bg-white">
                {item[`name_${langType}`]}
              </td>
              <td className="p-[4px] border border-black bg-white">
                {item.plan_id}
              </td>
              <td className="p-[4px] border border-black bg-white">
                {item.discount}%
              </td>
              <td className="p-[4px] border border-black bg-white ">
                {/* {item.status} */}
                <div className="flex gap-2 justify-center">
                  <Link to={`/update-cupon/${item?.id}`}>
                    <button className="px-[5px] border border-black rounded bg-[#0A9E5C] text-white">
                      {lang.edit}
                    </button>
                  </Link>
                  <button
                    className="px-[5px] border border-black rounded bg-[#da1414] text-white"
                    onClick={() => {
                      window.confirm(
                        "Are you sure you want to delete this cupon?"
                      ) && deleteCategory(item.id);
                    }}
                  >
                    {lang.delete}
                  </button>
                </div>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default Cupons;
