import React from "react";
import BlueBtn from "./BlueBtn";
import { FaUserAlt } from "react-icons/fa";
function Navbar({ setActiveBar, lang, active }) {
  return (
    <div className=" flex justify-between items-center p-[30px] pb-0 md:px-[50px] px-[20px]">
      <div className="flex items-center gap-5">
        <button
          onClick={() => setActiveBar(!active)}
          className="text-[#000] text-[30px]"
        >
          ☰
        </button>
        <h1 className="font-[600] text-[24px]">
          {lang.admin} <span className="text-[#038FF7]">{lang.panel}</span>
        </h1>
      </div>
      <div className="flex justify-end items-center gap-2">
        <BlueBtn text={<FaUserAlt />} />
      </div>
    </div>
  );
}

export default Navbar;
