import React, { useState, useEffect } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import ImageUpload from "../../ui_components/UplodaImage";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getRequest, postRequest } from "../../utils/request";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
function AddSubCategory() {
  const dispatch = useDispatch();
  const [allCategories, setAllCategories] = useState([]);
  async function getData() {
    const result = await getRequest("admin/categories", dispatch);
    setAllCategories(result.data);
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const { lang } = useLang();
  const { register, handleSubmit, control } = useForm();
  async function addSubCategory(inc) {
    let f = {
      ...inc,
      disable_comments: inc.disable_comments ? 1 : 0,
      featured: inc.featured ? 1 : 0,
    };
    const formData = new FormData();
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest(
      "admin/sub_categories",
      formData,
      dispatch
    );
    if (result.data) {
      toast.success("Category created successfully!");
      navigate("/subcategories");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addSubCategory(dataIn);
  };
  return (
    <div className="p-[50px]">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-2 items-start gap-[40px]"
      >
        <div className="grid gap-6 w-[100%] items-start">
          <h1 className="text-[24px]">{lang.add}</h1>
          <input
            required
            type="text"
            placeholder="Title_H1_ru:"
            className="p-[5px] rounded outline-none border border-black"
            {...register("title_ru")}
          />
          <input
            required
            type="text"
            placeholder="Title_H1_en:"
            className="p-[5px] rounded outline-none border border-black"
            {...register("title_en")}
          />
          <input
            required
            type="text"
            placeholder="Title_h2_ru:"
            className="p-[5px] rounded outline-none border border-black"
            {...register("name_ru")}
          />
          <input
            required
            type="text"
            placeholder="Title_h2_en:"
            className="p-[5px] rounded outline-none border border-black"
            {...register("name_en")}
          />
          <textarea
            cols="30"
            rows="10"
            required
            placeholder="description_ru:"
            className="p-[5px] rounded outline-none border border-black"
            {...register("description_ru")}
          ></textarea>
          <textarea
            cols="30"
            rows="10"
            required
            placeholder="description_en:"
            className="p-[5px] rounded outline-none border border-black"
            {...register("description_en")}
          ></textarea>
          <input
            required
            type="text"
            placeholder={lang.seo_title}
            className="p-[10px] bg-white border border-black rounded"
            {...register("seo_title")}
          />
          <input
            required
            type="text"
            placeholder={lang.seo_description}
            className="p-[10px] bg-white border border-black rounded"
            {...register("seo_description")}
          />

          <input
            required
            type="text"
            placeholder={lang.seo_keywords}
            className="p-[10px] bg-white border border-black rounded"
            {...register("seo_keywords")}
          />
          <input
            required
            type="text"
            placeholder={lang.seo_tag}
            className="p-[10px] bg-white border border-black rounded"
            {...register("seo_tag")}
          />
        </div>
        <div className="flex flex-col gap-3">
          <div className="border rounded-[15px] border-black bg-white overflow-hidden">
            <h1 className="p-[10px] border-b border-black text-white bg-[#038FF7]">
              {lang.post_details}
            </h1>
            <div className="p-[20px] grid gap-3">
              {/* <input
                required
                type="text"
                placeholder="Url slug:"
                value={`https://primesox.io/blog/${slugVal.replaceAll(
                  " ",
                  "-"
                )}`}
                {...register("url_slug")}
                className="p-[5px] rounded outline-none border border-black"
                // {...register("how_to_use")}
              /> */}

              <label htmlFor="" className="flex flex-col">
                Category
                <select
                  className="p-[5px] rounded outline-none border border-black cursor-pointer"
                  {...register("category_id")}
                >
                  {/* <option value="0">Block news</option>
                  <option value="1">New page</option> */}
                  {allCategories?.map((item, index) => (
                    <option value={item?.id} key={index}>
                      {item?.name_en}
                    </option>
                  ))}
                </select>
              </label>
              <label htmlFor="" className="flex flex-col">
                {lang.status}
                <select
                  {...register("status")}
                  className="p-[5px] rounded outline-none border border-black cursor-pointer"
                >
                  <option value="0">Publish</option>
                  <option value="1">Block</option>
                  <option value="2">Unblock</option>
                </select>
              </label>

              <div className="switch-wrapper flex gap-3 mt-[20px]">
                <label className="switch">
                  <input type="checkbox" {...register("disable_comments")} />
                  <span className="slider round"></span>
                </label>
                <p>{lang.disable_comments}</p>
              </div>
              <div className="switch-wrapper flex gap-3 mt-[10px]">
                <label className="switch">
                  <input type="checkbox" {...register("featured")} />
                  <span className="slider round"></span>
                </label>
                <p>{lang.featured}</p>
              </div>
            </div>
          </div>
          <div className="border rounded-[15px] border-black bg-white overflow-hidden">
            <h1 className="p-[10px] border-b border-black text-white bg-[#038FF7]">
              {lang.testimonial_details}
            </h1>
            <div className="p-[20px] grid gap-3">
              {/* <input
                required
                type="text"
                placeholder="How to use:"
                className="p-[5px] rounded outline-none border border-black"
                {...register("how_to_use")}
              /> */}
              <ImageUpload
                label={"Choose image"}
                name="photo"
                control={control}
              />
              <ImageUpload name="icon" control={control} />
            </div>
          </div>

          <BlueBtn text={lang.publish} type="submit" className={"w-[200px]"} />
        </div>
      </form>
    </div>
  );
}

export default AddSubCategory;
