import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../../../utils/request";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import BlueBtn from "../../../../ui_components/BlueBtn";
import { useLang } from "../../../../redux/selectors";

function BackMenu() {
  const dispatch = useDispatch();
  const { lang, langType } = useLang();
  const [data, setData] = useState([]);
  const [onEdit, setOnEdit] = useState(false);
  async function getData() {
    const result = await getRequest("admin/settings/side-menu/show", dispatch);
    setData(result.data);
  }
  async function deleteItem(id) {
    const result = await postRequest(
      "admin/settings/side-menu/delete/" + id,
      {},
      dispatch
    );
    console.log(result);
    if (result.status) {
      toast.success("has been deleted successfully");
      getData();
    } else {
      toast.error("Operation failed try again");
    }
  }
  async function createItem(inc) {
    let f = {
      ...inc,
    };
    const formData = new FormData();
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest(
      "admin/settings/side-menu/update",
      formData,
      dispatch
    );
    console.log(result);
    if (result.status) {
      toast.success("has been created successfully");
      getData();
    } else {
      toast.error("Operation failed try again");
    }
  }

  async function updateItem(inc) {
    let f = {
      ...inc,
    };
    const formData = new FormData();
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest(
      "admin/settings/side-menu/update/" + onEdit?.id,
      formData,
      dispatch
    );
    console.log(result);
    if (result.status) {
      toast.success("has been updated successfully");
      getData();
    } else {
      toast.error(result.message);
    }
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      title_ru: onEdit?.title_ru,
      title_en: onEdit?.title_en,
      link: onEdit?.link,
    },
  });

  const onSubmit = (data) => {
    if (onEdit) {
      updateItem(data);
      setOnEdit(false);
    } else {
      createItem(data);
    }
    reset();
  };
  return (
    <div className="grid grid-cols-2 items-start gap-[40px] p-[50px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-6 w-[100%] items-start">
          <h1 className="text-[18px]">{onEdit ? lang.edit : lang.add}</h1>
          <input
            type="text"
            placeholder={lang.title + "_ru"}
            defaultValue={onEdit?.title_ru}
            {...register("title_ru")}
            className="p-[10px] bg-white border border-black rounded"
          />
          <input
            type="text"
            placeholder={lang.title + "_en"}
            defaultValue={onEdit?.title_en}
            {...register("title_en")}
            className="p-[10px] bg-white border border-black rounded"
          />
          <input
            type="text"
            placeholder="Link:"
            defaultValue={onEdit?.link}
            {...register("link")}
            className="p-[10px] bg-white border border-black rounded"
          />
          <BlueBtn
            text={onEdit ? lang.edit : lang.create}
            className={"w-[150px]"}
            type="submit"
          />
        </div>
      </form>

      <div className="border rounded-[15px] border-black bg-white">
        <h1 className="p-[10px] border-b border-black">{lang.all_menus}</h1>
        <div className="p-[10px]">
          <table className="w-[100%] text-center">
            <tr>
              <td className="text-[18px] pb-[10px] font-semibold">
                {lang.title}
              </td> 
              <td className="text-[18px] pb-[10px] font-semibold">
                {lang.action}
              </td>
            </tr>
            {data?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item[`title_${langType}`]}</td>
                  <td className="py-[3px]">
                    <button
                      onClick={() => setOnEdit(item)}
                      className=" p-[3px] px-[10px] bg-[#0A9E5C] mr-2 rounded text-white text-[12px] border border-black"
                    >
                      {lang.edit}
                    </button>

                    <button
                      onClick={() => deleteItem(item?.id)}
                      className=" p-[3px] px-[10px] bg-red-500 rounded text-white text-[12px] border border-black"
                    >
                      {lang.delete}
                    </button>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
}

export default BackMenu;
