import React, { useEffect, useState } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import { Link } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { deleteRequest, getRequest } from "../../utils/request";
import toast from "react-hot-toast";
function AllUseCases() {
  const { lang, langType } = useLang();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  async function getData() {
    const result = await getRequest("admin/use_cases", dispatch);
    setData(result.data);
  }
  async function deleteItem(id) {
    const result = await deleteRequest("admin/use_cases/" + id, dispatch);
    console.log(result);
    if (result.data) {
      toast.success("has been deleted successfully");
      getData();
    } else {
      toast.error("Operation failed try again");
    }
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="p-[50px]">
      <h1 className="text-[32px] mb-[20px]">{lang.view_all}</h1>
      <Link to={"/create-case"}>
        <BlueBtn text={`+ ${lang.add}`} className={"w-[200px]"} />
      </Link>
      <div className="flex justify-between items-center mt-[20px] border-b border-b-black pb-[30px] mb-[30px]">
        <h1>
          {lang.total_products}: {data?.length}
        </h1>
        {/* <div className="bg-white rounded border border-black p-[6px] flex items-center ">
          <input
            type="text"
            placeholder={lang.search}
            className="bg-transparent outline-none"
          />
          <IoSearchOutline />
        </div> */}
      </div>
      <div className="grid grid-cols-4">
        <h1 className="text-center text-[16px] font-[500]">{lang.title}</h1>
        <h1 className="text-center text-[16px] font-[500]">{lang.link}</h1>
        <h1 className="text-center text-[16px] font-[500]">{lang.image}</h1>
        <h1 className="text-center text-[16px] font-[500]">{lang.action}</h1>
      </div>
      {data?.map((item, index) => (
        <div
          key={index}
          className={`grid grid-cols-4 gap-2 items-center p-[5px] ${
            index % 2 === 0 && " bg-white border rounded "
          }`}
        >
          <p className="text-center text-[14px] opacity-50">
            {item[`title_${langType}`]}
          </p>
          <p className="text-center text-[14px] opacity-50 whitespace-nowrap w-[200px] text-ellipsis overflow-hidden m-auto">
            {item.link}
          </p>
          <div>
            <img
              src={
                "https://api.primesox.io/files/use_cases/" +
                item.icon
              }
              className="w-[90px] h-[70px] object-cover rounded m-auto"
              alt=""
            />
          </div>

          <div className="flex flex-col gap-2">
            <Link
              to={`/update-case/${item.id}`}
              className="bg-[#0A9E5C] text-white border-solid border rounded-[6px] border-black text-[14px] m-auto w-[120px] text-center"
            >
              {lang.edit}
            </Link>
            <button
              onClick={() => {
                window.confirm(
                  "Are you sure you want to delete this?"
                ) && deleteItem(item.id);
              }}
              className="bg-[#E80000] text-white border-solid border rounded-[6px] border-black text-[14px] m-auto w-[120px]"
            >
              {lang.delete}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AllUseCases;
