import React, { useState, useEffect } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import { useLang } from "../../redux/selectors";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, putRequest } from "../../utils/request";

function UpdateReseller() {
  const { id } = useParams();
  const [data, setData] = useState(false);
  async function getData() {
    const result = await getRequest("admin/reseller/show/" + id, dispatch);
    setData(result?.data);
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { lang } = useLang();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ...data,
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function addTariff(inc) {
    const result = await putRequest("admin/reseller/edit/" + id, inc, dispatch);
    console.log(result);
    if (result.data) {
      toast.success("Reseller edited successfully!");
      navigate("/reseller");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addTariff(dataIn);
  };
  return (
    data && (
      <form onSubmit={handleSubmit(onSubmit)} className="p-[50px]">
        <h1 className="text-[40px]">{lang.edit}</h1>
        <div className="w-[50%] grid gap-[20px] mt-[30px]">
          <div className="grid gap-3 my-[40px]">
            <input
              type="text"
              placeholder={`name`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("name")}
              defaultValue={data?.name}
            />
            <input
              type="text"
              placeholder={`email`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("email")}
              defaultValue={data?.email}
            />
            <input
              type="text"
              placeholder={`Surname`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("surname")}
              defaultValue={data?.surname}
            />
            <input
              type="text"
              placeholder={`telegram`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("telegram")}
              defaultValue={data?.telegram}
            />
          </div>
          <BlueBtn text={lang.edit} type="submit" />
        </div>
      </form>
    )
  );
}

export default UpdateReseller;
