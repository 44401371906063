import React from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { postRequest } from "../../utils/request";
import toast from "react-hot-toast";
function AddUseCases() {
  const { lang } = useLang();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmit, register } = useForm();
  async function addFeedback(inc) {
    console.log(inc);
    let f = {
      ...inc,
      icon: inc.icon[0],
    };
    const formData = new FormData();
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest("admin/use_cases", formData, dispatch);
    if (result.data) {
      toast.success("created successfully!");
      navigate("/use-cases");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addFeedback(dataIn);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-[50px]">
      <div className="grid gap-6 w-[100%] items-start">
        <h1 className="text-[24px]">
          {lang.add} {lang.use_cases}
        </h1>
        <input
          type="text"
          placeholder="Title_ru:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("title_ru")}
        />
        <input
          type="text"
          placeholder="Title_en:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("title_en")}
        />
        <input
          type="text"
          placeholder="Link:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("link")}
        />
        <div className="p-[5px] pl-[10px]  bg-white border border-black rounded flex justify-between items-center">
          <input
            type="file"
            className="relative -left-[100px]"
            id="logo"
            {...register("icon")}
          />
          <label
            htmlFor="logo"
            className="cursor-pointer p-[4px] rounded bg-[#0A9E5C] border border-black text-[#fff]"
          >
            Choose file
          </label>
        </div>
        <BlueBtn text={lang.create} className={"w-[200px]"} type="submit" />
      </div>
    </form>
  );
}

export default AddUseCases;
