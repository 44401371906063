import React, { useState } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import { useLang } from "../../redux/selectors";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, putRequest } from "../../utils/request";

function UpdateFaq() {
  const [thisFaq, setThisFaq] = useState(false);
  const { lang } = useLang();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function getData() {
    const result = await getRequest("admin/faqs/" + id, dispatch);
    setThisFaq(result.data);
  }
  React.useEffect(() => {
    getData();
// eslint-disable-next-line react-hooks/exhaustive-deps 
 }, []);
  async function addSubCategory(inc) {
    const result = await putRequest("admin/faqs/" + id, inc, dispatch);
    if (result.data) {
      toast.success("Faq edited successfully!");
      navigate("/faq");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addSubCategory(dataIn);
  };
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ...thisFaq,
    },
  });
  return (
    thisFaq && (
      <form onSubmit={handleSubmit(onSubmit)} className="p-[50px]">
        <h1 className="text-[40px]">FAQ</h1>
        <div className="w-[50%] grid gap-[20px] mt-[30px]">
          <input
            type="text"
            required
            placeholder={lang.enter_question}
            className="p-[10px] bg-white border border-black rounded"
            {...register("quession_ru")}
            defaultValue={thisFaq.quession_ru}
          />
          <input
            type="text"
            required
            placeholder={lang.enter_answer}
            className="p-[10px] bg-white border border-black rounded"
            {...register("answer_ru")}
            defaultValue={thisFaq.answer_ru}
          />
          <input
            type="text"
            required
            placeholder={`${lang.enter_question}_en`}
            className="p-[10px] bg-white border border-black rounded"
            {...register("quession_en")}
            defaultValue={thisFaq.quession_en}
          />
          <input
            type="text"
            required
            placeholder={`${lang.enter_answer}_en`}
            className="p-[10px] bg-white border border-black rounded"
            {...register("answer_en")}
            defaultValue={thisFaq.answer_en}
          />
          <BlueBtn text={lang.edit} type="submit" />
        </div>
      </form>
    )
  );
}

export default UpdateFaq;
