import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { LuRefreshCcw } from "react-icons/lu";
import BlueBtn from "../../ui_components/BlueBtn";
import { Link } from "react-router-dom";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { deleteRequest, getRequest } from "../../utils/request";
import toast from "react-hot-toast";
function AllFaqs() {
  const [active, setActive] = useState(null);
  const { lang, langType } = useLang();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  async function getData() {
    const result = await getRequest("admin/faqs", dispatch);
    setData(result.data);
  }
  async function deleteItem(id) {
    const result = await deleteRequest("admin/faqs/" + id, dispatch);
    console.log(result);
    if (result.data) {
      toast.success("Faq has been deleted successfully");
      getData();
    } else {
      toast.error("Operation failed try again");
    }
  }
  useEffect(() => {
    getData();
// eslint-disable-next-line react-hooks/exhaustive-deps 
 }, []);
  function Accordion({ title, children, index, idDelete }) {
    return (
      <div className="border-b border-[#aaaaaa]  p-[20px]">
        <div
          className="flex justify-between items-center p-[20px] cursor-pointer"
          onClick={() => {
            if (active === index) {
              setActive(null);
            } else {
              setActive(index);
            }
          }}
        >
          <h1 className="text-[18px] font-semibold">{title}</h1>
          <div className="flex items-center gap-3 text-[20px]">
            {active === index ? <IoIosArrowUp /> : <IoIosArrowDown />}
            <Link to={`/update-faq/${idDelete}`}>
              <LuRefreshCcw />
            </Link>
            <button
              onClick={() => {
                window.confirm("Are you sure you want to delete this faq?") &&
                  deleteItem(idDelete);
              }}
            >
              &times;
            </button>
          </div>
        </div>
        {active === index && <div className="p-[20px]">{children}</div>}
      </div>
    );
  }

  return (
    <div className="p-[50px]">
      <h1 className="text-[40px]">FAQ</h1>
      <div className="p-[70px]">
        <h1 className="text-[32px] mb-[40px]">{lang.long_faq}</h1>
        {data?.map((item, i) => (
          <Accordion
            index={i}
            key={i}
            title={item[`quession_${langType}`]}
            children={item[`answer_${langType}`]}
            idDelete={item.id}
          />
        ))}
        <Link to={"/add-faq"}>
          <BlueBtn text={lang.add} className={"mt-[30px]"} />
        </Link>
      </div>
    </div>
  );
}

export default AllFaqs;
