import React, { useState, useEffect } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import { useLang } from "../../redux/selectors";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, putRequest } from "../../utils/request";

function UpdateTariff() {
  const { id } = useParams();
  const [data, setData] = useState(false);
  async function getData() {
    const result = await getRequest("admin/plans/" + id, dispatch);
    setData(result?.data);
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { lang } = useLang();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ...data,
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function addTariff(inc) {
    const result = await putRequest("admin/plans/" + id, inc, dispatch);
    console.log(result);
    if (result.data) {
      toast.success("Tariff edited successfully!");
      navigate("/tarifs");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addTariff(dataIn);
  };
  return (
    data && (
      <form onSubmit={handleSubmit(onSubmit)} className="p-[50px]">
        <h1 className="text-[40px]">{lang.edit}</h1>
        <div className="w-[50%] grid gap-[20px] mt-[30px]">
          <div className="grid gap-3 my-[40px]">
            <input
              type="text"
              placeholder={`${lang.tariff_name}_ru`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("name_ru")}
              defaultValue={data?.name_ru}
            />
            <input
              type="text"
              placeholder={`${lang.tariff_name}_en`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("name_en")}
              defaultValue={data?.name_en}
            />

            <input
              type="text"
              defaultValue={data?.price_month}
              placeholder={`${lang.price} ($) month:`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("price_month")}
            />
            <input
              type="text"
              placeholder={`${lang.price} ($) traffic:`}
              defaultValue={data?.price_traffic}
              className="p-[10px] bg-white border border-black rounded"
              {...register("price_traffic")}
            />
            <input
              type="text"
              placeholder={`${lang.traffic} (GB):`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("traffic")}
              defaultValue={data?.traffic}
            />
            <input
              type="text"
              placeholder="Порты:"
              className="p-[10px] bg-white border border-black rounded"
              {...register("port")}
              defaultValue={data?.port}
            />
            <input
              type="text"
              placeholder="Цвет рамки:"
              className="p-[10px] bg-white border border-black rounded"
              {...register("color_frame")}
              defaultValue={data?.color_frame}
            />
            <select
              placeholder="Цвет рамки:"
              className="p-[10px] bg-white border border-black rounded"
              {...register("type")}
              defaultValue={data?.type}
            >
              <option value="dinamic">Динамический</option>
              <option value="static">Статический</option>
              <option value="mobile">Мобильный</option>
            </select>
          </div>

          
          <BlueBtn text={lang.edit} type="submit" />
        </div>
      </form>
    )
  );
}

export default UpdateTariff;
