import React, { useEffect, useState } from "react";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../utils/request";
import EditProfile from "./EditProfile";

function Clients() {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [OnEdit, setOnEdit] = useState({});
  const [pageNation, setPageNation] = useState(10);
  const dispatch = useDispatch();
  async function getData() {
    const result = await getRequest("admin/client/all-clients", dispatch);
    setData(result.data);
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function startModal() {
    setOpenModal(true);
  }
  function closeModal() {
    setOpenModal(false);
    // getData();
  }

  const { lang } = useLang();
  function formSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    console.log(data);
    postRequest("admin/client/search", data, dispatch)
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className="p-[30px] px-[60px]">
      {openModal && (
        <EditProfile
          OnEdit={OnEdit}
          closeModal={closeModal}
          getData={getData}
        />
      )}
      <h1 className="text-[32px] font-[500] mb-[30px]">{lang.clients}</h1>
      <form onSubmit={formSubmit}>
        <div className="flex gap-4">
          <input
            // type="email"
            className="bg-white p-[10px] border border-black rounded outline-none min-w-[290px]"
            placeholder="Email"
            name="email"
          />
          <select
            className="bg-white p-[10px] border border-black rounded outline-none min-w-[290px]"
            id=""
            name="status"
          >
            <option value="">{lang.status}:</option>
            <option value="active">active</option>
            <option value="block">block</option>
          </select>
        </div>
        <div className="flex gap-4 mt-[30px]">
          <button className="bg-transparent border rounded border-[#1ABB00] p-[4px] px-[10px] text-[#1ABB00]">
            {lang.apply}
          </button>
          <button
            type="reset"
            className="bg-transparent border rounded border-[#C00000] p-[4px] px-[10px] text-[#C00000]"
          >
            {lang.clear}
          </button>
        </div>
      </form>
      <br />
      <p className="text-[20px]">
        {lang.on_page}: {data?.length > 10 ? 10 : data?.length}
      </p>
      <p className="text-[20px]">
        {lang.displayed}: {data?.length > 10 ? pageNation : data?.length} /{" "}
        {data?.length}
      </p>

      <table className="border-collapse w-full border border-black text-center rounded-md my-[40px]">
        <tr>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            №
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            E-mail:
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.balance}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.status}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.type}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            Refs
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.controls}
          </td>
        </tr>
        {data?.map((item, i) => {
          return (
            <tr className={(i + 1) % 2 === 0 && "gray-row"} key={i}>
              <td className="p-[4px] border border-black">{i + 1}</td>
              <td className="p-[4px] border border-black">{item?.email}</td>
              <td className="p-[4px] border border-black">{item?.balance}</td>
              <td className="p-[4px] border border-black">{item?.status}</td>
              <td className="p-[4px] border border-black">{item?.type}</td>
              <td className="p-[4px] border border-black">{item?.refs}</td>
              <td className="p-[4px] border border-black">
                <button
                  className="bg-[#0A9E5C] border rounded border-[#000000] p-[4px] px-[10px] text-[#fff] w-full"
                  onClick={() => {
                    startModal();
                    setOnEdit(item);
                  }}
                >
                  {lang.edit}
                </button>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default Clients;
