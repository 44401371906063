import React, { useState } from "react";
import Site from "./tabs/Site";
import PersonalCabinet from "./tabs/PersonalCabinet";
import { useLang } from "../../redux/selectors";

function Settings() {
  const [active, setActive] = useState(0);
  const {lang} = useLang()
  const tabs = [
    {
      id: 1,
      name: lang.site,
      component: <Site />,
    },
    {
      id: 2,
      name: lang.personal_account,
      component: <PersonalCabinet />,
    },
  ];
  return (
    <div className="p-[30px] px-[60px]">
      <h1 className="text-[32px] font-[500] mb-[30px]">{lang.settings}</h1>
      <div className="flex gap-4">
        {tabs.map((item, i) => (
          <button
            key={item.id}
            className={`border border-black rounded-full p-[5px] px-[20px] text-[12px] ${
              i === active && " bg-[#038FF7] text-white "
            }`}
            onClick={() => setActive(i)}
          >
            {item.name}
          </button>
        ))}
      </div>
      <div className="mt-6">{tabs[active].component}</div>
    </div>
  );
}

export default Settings;
