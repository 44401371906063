import React from "react";

function DatePicker({ text, defaultValue, name = "", required = true }) {
  const originalDateString = defaultValue || new Date();
  const originalDate = new Date(originalDateString);

  // Extract year, month, and day
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
  const day = String(originalDate.getDate()).padStart(2, "0");

  // Construct the date string in "yyyy-MM-dd" format
  const formattedDate = `${year}-${month}-${day}`;
  return (
    <div className="flex flex-col border border-black px-[10px] rounded w-full bg-white">
      <label className="text-[14px] leadin-[10px] font-[500]">{text}</label>
      <input
        type="date"
        className="w-full bg-transparent outline-none cursor-pointer"
        // defaultValue={formattedDate}
        name={name}
        required={required}
      />
    </div>
  );
}

export default DatePicker;
