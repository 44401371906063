import React, { useEffect, useState } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import { LuRefreshCcw } from "react-icons/lu";
import BlueBtn from "../../ui_components/BlueBtn";
import { Link } from "react-router-dom";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { deleteRequest, getRequest } from "../../utils/request";
import toast from "react-hot-toast";
function Blogs() {
  const { lang, langType } = useLang();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  async function getData() {
    const result = await getRequest("admin/blogs", dispatch);
    setData(result.data);
    console.log(result.data, "----------->", langType);
  }
  async function deleteItem(id) {
    const result = await deleteRequest("admin/blogs/" + id, dispatch);
    console.log(result);
    if (result.data) {
      toast.success("Tariff has been deleted successfully");
      getData();
    } else {
      toast.error("Operation failed try again");
    }
  }
  useEffect(() => {
    getData();
// eslint-disable-next-line react-hooks/exhaustive-deps 
 }, []);
  return (
    <div className="p-[50px]">
      <h1 className="text-[40px]">{lang.blog}</h1>

      <div className="grid grid-cols-4 my-[30px] gap-8">
        {data?.map((item, i) => (
          <div
            className="flex flex-col bg-white shadow-xl rounded-lg overflow-hidden"
            key={i}
          >
            <video
              controls
              src={"https://api.primesox.io/files/blogs/" + item.file}
              className="w-full h-[200px] bg-black"
            ></video>
            <div className="p-[20px] text-[20px]">
              <div className="flex justify-between">
                <span>{lang.title}:</span>
                <span className="opacity-50">{item[`title_${langType}`]}</span>
              </div>
              <div className="flex justify-between">
                <span>{lang.duration}:</span>
                <span className="opacity-50">{Number(item?.duration_file).toFixed()} sec.</span>
              </div>
              <div className="flex justify-between">
                <span>{lang.update_date}:</span>
                <span className="opacity-50">{item?.created_at}</span>
              </div>
              <div className="flex justify-between">
                <span>{lang.file_size}:</span>
                <span className="opacity-50">{item?.size_file}МБ</span>
              </div>
              <div className="flex justify-center gap-[20px] mt-[20px]">
                <button
                  onClick={() => {
                    window.confirm(
                      "Are you sure you want to delete this blog?"
                    ) && deleteItem(item?.id);
                  }}
                  className="text-white text-[25px] p-[10px] rounded bg-red-600"
                >
                  <FaRegTrashCan />
                </button>
                <Link
                  to={`/update-blog/${item?.id}`}
                  className="text-white text-[25px] p-[10px] rounded bg-[#3788f1]"
                >
                  <LuRefreshCcw />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Link to={"/add-blog"}>
        <BlueBtn text={lang.add} />
      </Link>
    </div>
  );
}

export default Blogs;
