import React from "react";
import BlueBtn from "../../../../ui_components/BlueBtn";
import { useLang } from "../../../../redux/selectors";

function Blog() {
  const {lang} = useLang()
  return (
    <div className="grid grid-cols-2 items-start gap-[40px]">
      <div className="grid gap-6 w-[100%] items-start">
        <h1 className="text-[24px]">{lang.blog_account}</h1>
        <h1 className="text-[18px]">{lang.add}</h1>
        <input
          type="text"
          placeholder={lang.title}
          className="p-[10px] bg-white border border-black rounded"
        />

        <BlueBtn text={lang.create} className={"w-[200px]"} />
      </div>
      <div className="border rounded-[15px] border-black bg-white">
        <h1 className="p-[10px] border-b border-black">{lang.all_menus}</h1>
        <div className="p-[10px]">
          <table className="w-[100%] text-center">
            <tr>
              <td className="text-[18px] pb-[10px] font-semibold">{lang.title}</td>

              <td className="text-[18px] pb-[10px] font-semibold">{lang.action}</td>
            </tr>
            <tr>
              <td>News</td>
              <td>
                <button className=" p-[3px] px-[10px] bg-[#0A9E5C] mr-2 rounded text-white text-[12px] border border-black">
                  {lang.edit}
                </button>

                <button className=" p-[3px] px-[10px] bg-red-500 rounded text-white text-[12px] border border-black">
                  {lang.delete}
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Blog;
