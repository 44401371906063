import React, { useState } from "react";
import BuisnessInfo from "./siteTabs/BuisnessInfo";
import Social from "./siteTabs/Social";
import Analytics from "./siteTabs/Analytics";
import BinAnalytics from "./siteTabs/BinAnalytics";
import YandexAnalytics from "./siteTabs/YandexAnalytics";
import Legal from "./siteTabs/Legal";
import Seo from "./siteTabs/Seo";
import Menu from "./siteTabs/Menu";
import { useLang } from "../../../redux/selectors";
import AllUseCases from "./siteTabs/AllUseCases";

function Site() {
  const [active, setActive] = useState(0);
  const {lang} = useLang()
  const tabs = [
    {
      id: 1,
      name: lang.businessInformation,
      component: <BuisnessInfo />,
    },
    {
      id: 2,
      name: lang.socialNetworks,
      component: <Social />,
    },
    {
      id: 3,
      name: lang.googleAnalytics,
      component: <Analytics />,
    },
    {
      id: 4,
      name: lang.binAnalytics,
      component: <BinAnalytics />,
    },
    {
      id: 5,
      name: lang.yandexAnalytics,
      component: <YandexAnalytics />,
    },
    {
      id: 6,
      name: lang.documents,
      component: <Legal />,
    },
    {
      id: 7,
      name: lang.seo,
      component: <Seo />,
    },
    {
      id: 8,
      name: lang.menu,
      component: <Menu />,
    },
    {
      id: 9,
      name: lang.language,
      component: "",
    },
    {
      id: 10,
      name: "All use cases",
      component: <AllUseCases />,
    },
  ];
  return (
    <div>
      <div className="flex gap-4">
        {tabs.map((item, i) => (
          <button
            key={item.id}
            className={`border border-black rounded-full p-[5px] px-[10px]  text-[12px] ${
              i === active && " bg-[#038FF7] text-white "
            }`}
            onClick={() => setActive(i)}
          >
            {item.name}
          </button>
        ))}
      </div>
      <div className="mt-6">{tabs[active].component}</div>
    </div>
  );
}

export default Site;
