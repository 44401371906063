import React, { useEffect, useState } from "react";
import BlueBtn from "../../../../ui_components/BlueBtn";
import { useLang } from "../../../../redux/selectors";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { getRequest, postRequest } from "../../../../utils/request";

function BinAnalytics() {
  const { lang } = useLang();
  const dispatch = useDispatch();
  const [data, setData] = useState(true);
  async function getData() {
    const result = await getRequest("admin/settings/analytic/show/1", dispatch);
    setData(result.data);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ...data,
    },
  });
  async function updateSocial(inc) {
    let f = {
      ...inc,
      type: "bin",
    };
    const formData = new FormData();
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest(
      "admin/settings/analytic/update/1",
      formData,
      dispatch
    );
    if (result.data) {
      toast.success("Information updated successfully!");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    updateSocial(dataIn);
  };
  return (
    data && (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid gap-6 w-[60%] items-start"
      >
        <textarea
          cols="30"
          rows="10"
          {...register("analytic")}
          placeholder={lang.binAnalytics}
          defaultValue={data?.bin_analytic}
          minLength={10}
          required
          className="p-[10px] bg-white border border-black rounded"
        ></textarea>
        <BlueBtn text={lang.update} className={"w-[200px]"} type="  " />
      </form>
    )
  );
}

export default BinAnalytics;
