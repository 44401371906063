import React, { useEffect } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getRequest, postRequest } from "../../utils/request";
import toast from "react-hot-toast";
import { useState } from "react";
function UpdateBlog() {
  const [video, setVideo] = useState({
    duration: 0,
    size: 0,
  });
  const [file, setFile] = useState({});
  const { lang } = useLang();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(false);

  async function getData() {
    const result = await getRequest("admin/blogs/" + id, dispatch);
    setData(result?.data);
    setVideo(() => ({
      duration: result?.data?.duration_file,
      size: Number(result?.data?.size_file) * 1024 * 1024,
    }));
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ...data,
    },
  });
  async function updateBlog(inc) {
    let f = {
      title_ru: inc.title_ru,
      title_en: inc.title_en,
      file: file,
      link: inc.link,
      duration_file: video?.duration,
      size_file: Math.round(video?.size / 1024 / 1024, 1),
    };
    const formData = new FormData();
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest(
      "admin/blogUpdate/" + id,
      formData,
      dispatch
    );
    if (result.data) {
      toast.success("Blog has been edited successfully!");
      navigate("/blogs");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    updateBlog(dataIn);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      // Get video duration using HTML5 video element
      const video = document.getElementById("video");
      video.src = URL.createObjectURL(file);
      video.onloadedmetadata = () => {
        setVideo((prevInfo) => ({
          ...prevInfo,
          duration: video.duration,
        }));
      };

      // Get video size
      const sizeInBytes = file.size;
      setVideo((prevInfo) => ({
        ...prevInfo,
        size: sizeInBytes,
      }));
    }
  };
  return (
    data && (
      <form onSubmit={handleSubmit(onSubmit)} className="p-[50px]">
        <h1 className="text-[40px]">{lang.blog}</h1>
        <div className="grid gap-4 my-[30px]">
          <input
            type="text"
            placeholder={lang.paste_link}
            {...register("link")}
            defaultValue={data?.link}
            className="p-[10px] bg-white border border-black rounded"
          />
          <span>{lang.or}</span>

          <div className="p-[10px] bg-white border border-black rounded">
            <label
              htmlFor="file"
              className="cursor-pointer flex justify-between items-center"
            >
              <input
                type="file"
                id="file"
                className="hidden"
                placeholder="Вставить ответ"
                accept="video/*"
                onChange={handleFileChange}
              />
              <span>{lang.select_file}</span>
              <span className="bg-[#038FF7] text-[#fff] p-[10px] px-[15px] rounded border border-black">
                {lang.upload}
              </span>
            </label>
          </div>
          <input
            type="text"
            required
            placeholder={lang.title}
            defaultValue={data?.title_ru}
            className="p-[10px] bg-white border border-black rounded"
            {...register("title_ru")}
          />
          <input
            type="text"
            required
            placeholder={`${lang.title}_en`}
            defaultValue={data?.title_en}
            className="p-[10px] bg-white border border-black rounded"
            {...register("title_en")}
          />
          <textarea
            cols="30"
            rows="10"
            required
            placeholder="description_ru:"
            className="p-[5px] rounded outline-none border border-black"
            {...register("description_ru")}
            defaultValue={data?.description_ru}
          ></textarea>
          <textarea
            cols="30"
            rows="10"
            required
            placeholder="description_en:"
            className="p-[5px] rounded outline-none border border-black"
            {...register("description_en")}
            defaultValue={data?.description_en}
          ></textarea>
          <input
            required
            type="text"
            placeholder={lang.seo_title}
            className="p-[10px] bg-white border border-black rounded"
            {...register("seo_title")}
            defaultValue={data?.seo_title}
          />
          <input
            required
            type="text"
            placeholder={lang.seo_description}
            className="p-[10px] bg-white border border-black rounded"
            {...register("seo_description")}
            defaultValue={data?.seo_description}
          />

          <input
            required
            type="text"
            placeholder={lang.seo_keywords}
            className="p-[10px] bg-white border border-black rounded"
            {...register("seo_keywords")}
            defaultValue={data?.seo_keywords}
          />
          <input
            required
            type="text"
            placeholder={lang.seo_tag}
            className="p-[10px] bg-white border border-black rounded"
            {...register("seo_tag")}
            defaultValue={data?.seo_tag}
          />
          <div className="flex flex-col bg-white shadow-xl rounded-lg overflow-hidden max-w-[400px]">
            <video
              controls
              src={"https://api.primesox.io/files/blogs/" + data?.file}
              className="w-full h-[200px]"
              id="video"
            ></video>
            <div className="p-[20px] text-[20px]">
              <div className="flex justify-between">
                <span>{lang.duration}:</span>
                <span className="opacity-50">
                  {Number(video?.duration).toFixed()} sec.
                </span>
              </div>
              <div className="flex justify-between">
                <span>{lang.update_date}:</span>
                <span className="opacity-50">
                  {new Date().toLocaleDateString()}
                </span>
              </div>
              <div className="flex justify-between">
                <span>{lang.file_size}:</span>
                <span className="opacity-50">
                  {Math.round(video?.size / 1024 / 1024, 1)}МБ
                </span>
              </div>
            </div>
          </div>
          <BlueBtn
            text={lang.create}
            className={"max-w-[200px]"}
            type="submit"
          />
        </div>
      </form>
    )
  );
}

export default UpdateBlog;
