import React, { useEffect, useState } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import { Link } from "react-router-dom";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { deleteRequest, getRequest } from "../../utils/request";
import toast from "react-hot-toast";
function Partners() {
  const { lang, langType } = useLang();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  async function getData() {
    const result = await getRequest("admin/partners", dispatch);
    setData(result.data);
    console.log(result.data, "----------->", langType);
  }
  async function deleteItem(id) {
    const result = await deleteRequest("admin/partners/" + id, dispatch);
    console.log(result);
    if (result.data) {
      toast.success("Tariff has been deleted successfully");
      getData();
    } else {
      toast.error("Operation failed try again");
    }
  }
  useEffect(() => {
    getData();
// eslint-disable-next-line react-hooks/exhaustive-deps 
 }, []);
  return (
    <div className="p-[30px] px-[60px]">
      <h1 className="text-[32px] font-[500] mb-[30px]">{lang.partners}</h1>
      <Link to={"/create-partner"}>
        <BlueBtn text={lang.create} className={"min-w-[200px]"} />
      </Link>
      <br />
      <p className="text-[20px]">
        {lang.on_page}: {data?.length}
      </p>
      {/* <p className="text-[20px]">{lang.displayed}: 10 / 2602</p> */}

      <table className="border-collapse w-full border border-black text-center rounded-md my-[40px]">
        <tr>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            №
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.partner}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.link}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.description}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.file}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            Управление
          </td>
        </tr>
        {data?.map((item, i) => {
          return (
            <tr className={(i + 10) % 2 === 0 && "gray-row"} key={i}>
              <td className="p-[4px] border border-black bg-white">{i + 1}</td>
              <td className="p-[4px] border border-black bg-white">
                <p className="w-[200px] m-auto overflow-hidden overflow-ellipsis whitespace-nowrap">
                  {item[`name_${langType}`]}
                </p>
              </td>
              <td className="p-[4px] border border-black bg-white">
                <p className="w-[200px] m-auto overflow-hidden overflow-ellipsis whitespace-nowrap">
                  {item?.link}
                </p>
              </td>
              <td className="p-[4px] border border-black bg-white">
                <p className="w-[200px] m-auto overflow-hidden overflow-ellipsis whitespace-nowrap">
                  {item[`description_${langType}`]}
                </p>
              </td>
              <td className="p-[4px] border border-black bg-white">
                <img
                  src={"https://api.primesox.io/files/partners/" + item?.file}
                  className="w-[90px] h-[70px] object-cover rounded m-auto"
                  alt=""
                />
              </td>
              <td className="p-[4px] border border-black bg-white">
                <div className="flex flex-col gap-2 justify-center">
                  <Link to={`/update-partner/${item?.id}`}>
                    <button className="px-[5px] w-[120px] border border-black rounded bg-[#0A9E5C] text-white">
                      {lang.edit}
                    </button>
                  </Link>
                  <div>
                    <button
                      className="px-[5px] w-[120px] border border-black rounded bg-[#da1414] text-white"
                      onClick={() => {
                        window.confirm(
                          "Are you sure you want to delete this partner?"
                        ) && deleteItem(item.id);
                      }}
                    >
                      {lang.delete}
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default Partners;
