import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changeLanguage } from "../redux/slices/langSlice";

const LangSwitcher = ({ lang }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const toggleLangSwitcher = (e) => {
    dispatch(changeLanguage(e));
    setIsOpen(!isOpen);
  };
  const openLangSwitcher = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex flex-col gap-[8px] items-start">
      <button onClick={openLangSwitcher}>{lang.language}</button>
      {isOpen && (
        <div className="flex flex-col gap-[8px] pl-[10px] cursor-pointer">
          <p onClick={() => toggleLangSwitcher("en")}>English</p>
          <p onClick={() => toggleLangSwitcher("ru")}>Русский</p>
        </div>
      )}
    </div>
  );
};

export default LangSwitcher;
