import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { useAuth, useLang } from "../redux/selectors";
import LangSwitcher from "./LanguageSwitcher";
import {
  BlogSVG,
  CeoTagSVG,
  Clients,
  CuponSVG,
  Dashboard,
  FAQSVG,
  OrdersSVG,
  PostingSVG,
  SettingsSVG,
  SubSVG,
} from "./Svgs";
import DropdDown from "./DropDown";
import { useDispatch } from "react-redux";
import { IoIosLogOut } from "react-icons/io";
import { getRequest } from "../utils/request";
function Sidebar({ currentPage }) {
  const [load, setLoad] = useState(true);
  const { lang } = useLang();
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function getData() {
    const result = await getRequest("admin/me", dispatch);
    if (result.name !== "AxiosError") {
      dispatch({ type: "auth/authChange", payload: true });
      dispatch({ type: "auth/setUser", payload: result });
      navigate("/");
      setLoad(false);
    } else {
      navigate("/login");
    }
    setLoad(false);
  }
  const logout = () => {
    localStorage.removeItem("token");
    dispatch({ type: "auth/authChange", payload: false });
    navigate("/login");
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);
  const sidebarItems = [
    {
      icon: <Dashboard />,
      text: "Dashboard",
      link: "/",
      isDrop: false,
    },
    {
      icon: <Clients />,
      text: lang.clients,
      link: "/clients",
      isDrop: false,
    },
    {
      icon: <OrdersSVG />,
      text: lang.orders,
      link: "/orders",
      isDrop: false,
    },
    // {
    //   icon: <PostingSVG />,
    //   text: lang.posting,
    //   isDrop: true,
    //   links: ["/add-post", "/posts"],
    //   texts: [lang.add, lang.showAll],
    //   // link: "/settings",
    // },
    {
      icon: <CeoTagSVG />,
      text: lang.seoTag,
      link: "/ceo-tag",
      isDrop: false,
    },
    {
      icon: <PostingSVG />,
      text: lang.categories,
      isDrop: true,
      links: ["/add-category", "/categories"],
      texts: [lang.add, lang.showAll],
      // link: "/settings",
    },
    {
      icon: <SubSVG />,
      text: lang.subCategory,
      isDrop: true,
      links: ["/add-subcategory", "/subcategories"],
      texts: [lang.add, lang.showAll],
    },
    {
      icon: <CeoTagSVG />,
      text: lang.reviews,
      isDrop: true,
      links: ["/add-testimonial", "/testimonials"],
      texts: [lang.add, lang.showAll],
      // link: "/settings",
    },
    {
      icon: <SubSVG />,
      text: lang.deposits,
      link: "/deposit",
      isDrop: false,
    },
    {
      icon: <CeoTagSVG />,
      text: lang.use_cases,
      isDrop: true,
      links: ["/create-case", "/use-cases"],
      texts: [lang.add, lang.showAll],
    },
    {
      icon: <img src={require("../assets/images/tarif.png")} alt="im" />,
      text: lang.tariffs,
      link: "/tarifs",
      isDrop: false,
    },
    {
      icon: <SettingsSVG />,
      text: lang.settings,
      link: "/settings",
      isDrop: false,
    },
    {
      icon: <FAQSVG />,
      text: lang.faq,
      link: "/faq",
      isDrop: false,
    },
    {
      icon: <BlogSVG />,
      text: lang.blog,
      link: "/blogs",
      isDrop: false,
    },
    {
      icon: <img src={require("../assets/images/admin.png")} alt="im" />,
      text: lang.administrators,
      link: "/admins",
      isDrop: false,
    },
    {
      icon: <img src={require("../assets/images/partner.png")} alt="im" />,
      text: lang.partners,
      link: "/partners",
      isDrop: false,
    },

    {
      icon: <img src={require("../assets/images/admin.png")} alt="im" />,
      text: "Reseller",
      link: "/reseller",
      isDrop: false,
    },
    {
      icon: <CuponSVG />,
      text: lang.coupons,
      link: "/cupons",
      isDrop: false,
    },
    // {
    //   icon: <img src={require("../assets/images/partner.png")} alt="im" />,
    //   text: "Управление сайтом",
    //   link: "/site-manager",
    //   isDrop: false,
    // },
  ];
  const [activeBar, setActiveBar] = useState(true);
  return (
    !load && (
      <div className="md:flex">
        <div
          className={`transition-all ${
            !activeBar ? "w-[0vw] min-w-[0px]" : "w-[24vw] min-w-[290px]"
          }`}
        >
          <div
            className={`z-[90] transition-all w-[20%] min-w-[290px] bg-[#ffffff] text-[#000] fixed  h-screen md:px-[20px] px-[15px] shadow-lg border-r border-r-black  ${
              !activeBar ? "md:-left-[25%] -left-[100%]" : "left-[1px]"
            }`}
          >
            <div className="flex flex-col gap-[8px] w-[95%] overflow-y-auto h-[100%] md:py-[20px] py-[15px]">
              {
                // eslint-disable-next-line array-callback-return
                sidebarItems.map((item, i) =>
                  item.isDrop ? (
                    <div key={i}>
                      <DropdDown
                        dropText={
                          <div className="flex items-center gap-[10px]">
                            {item.icon && item.icon}
                            <span>{item.text}</span>
                          </div>
                        }
                        dropContent={
                          <div className="flex flex-col gap-[10px] ml-[10px]">
                            {item.links.map((link, i) => (
                              <NavLink
                                key={i}
                                to={link}
                                className="flex items-center gap-[10px]"
                              >
                                <div className="w-[20px] h-[20px] border border-[#AA70E6] rounded-full p-[2px]">
                                  <div className="w-full h-full checkside rounded-full"></div>
                                </div>
                                <span>{item.texts[i]}</span>
                              </NavLink>
                            ))}
                          </div>
                        }
                      />
                    </div>
                  ) : (
                    <NavLink
                      key={i}
                      to={item.link}
                      className="flex items-center gap-[10px]"
                    >
                      {item.icon && item.icon}
                      <span>{item.text}</span>
                    </NavLink>
                  )
                )
              }
              {/* <span>Язык</span> */}
              <LangSwitcher lang={lang} />
              <div>
                <button
                  className="text-[16px] flex items-center gap-2"
                  onClick={logout}
                >
                  Log out
                  <IoIosLogOut />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <Navbar setActiveBar={setActiveBar} lang={lang} active={activeBar} />
          {currentPage}
        </div>
      </div>
    )
  );
}

export default Sidebar;
