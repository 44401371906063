// import React from "react";
// import BlueBtn from "../../../../ui_components/BlueBtn";
// import { useLang } from "../../../../redux/selectors";

// function Legal() {
//   const {lang} = useLang()
//   return (
//     <div className="grid gap-6 w-[60%] items-start">

//       <BlueBtn text={lang.update} className={"w-[200px]"} />
//     </div>
//   );
// }

// export default Legal;

import React, { useEffect, useState } from "react";
import BlueBtn from "../../../../ui_components/BlueBtn";
import { useLang } from "../../../../redux/selectors";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { getRequest, postRequest } from "../../../../utils/request";

function BinAnalytics() {
  const { lang } = useLang();
  const dispatch = useDispatch();
  const [data, setData] = useState(false);
  async function getData() {
    const result = await getRequest("admin/settings/document/show/1", dispatch);
    setData(result?.data);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ...data,
    },
  });
  async function updateDocument(inc) {
    const result = await postRequest(
      "admin/settings/document/update/1",
      inc,
      dispatch
    );
    if (result.status) {
      toast.success("Documents updated successfully!");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    updateDocument(dataIn);
  };
  if (data === null || data)
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid gap-6 w-[60%] items-start"
      >
        <textarea
          cols="30"
          rows="5"
          minLength={5}
          required
          placeholder={lang.termsOfUse + "_ru"}
          defaultValue={data?.terms_ru}
          {...register("terms_ru")}
          className="p-[10px] bg-white border border-black rounded"
        ></textarea>
        <textarea
          cols="30"
          rows="5"
          minLength={5}
          required
          placeholder={lang.privacyPolicy + "_ru"}
          defaultValue={data?.privacy_policy_ru}
          {...register("privacy_policy_ru")}
          className="p-[10px] bg-white border border-black rounded"
        ></textarea>
        <textarea
          cols="30"
          rows="5"
          minLength={5}
          required
          placeholder={lang.cookiePolicy + "_ru"}
          defaultValue={data?.cookie_policy_ru}
          {...register("cookie_policy_ru")}
          className="p-[10px] bg-white border border-black rounded"
        ></textarea>
        <textarea
          cols="30"
          rows="5"
          minLength={5}
          required
          placeholder={lang.refundAndCancellationPolicy + "_ru"}
          defaultValue={data?.cancellation_policy_ru}
          {...register("cancellation_policy_ru")}
          className="p-[10px] bg-white border border-black rounded"
        ></textarea>
        <textarea
          cols="30"
          rows="5"
          minLength={5}
          required
          placeholder={lang.serviceLevelAgreement + "_ru"}
          defaultValue={data?.service_level_ru}
          {...register("service_level_ru")}
          className="p-[10px] bg-white border border-black rounded"
        ></textarea>

        <textarea
          cols="30"
          rows="5"
          minLength={5}
          required
          placeholder={lang.termsOfUse + "_en"}
          defaultValue={data?.terms_en}
          {...register("terms_en")}
          className="p-[10px] bg-white border border-black rounded"
        ></textarea>
        <textarea
          cols="30"
          rows="5"
          minLength={5}
          required
          placeholder={lang.privacyPolicy + "_en"}
          defaultValue={data?.privacy_policy_en}
          {...register("privacy_policy_en")}
          className="p-[10px] bg-white border border-black rounded"
        ></textarea>
        <textarea
          cols="30"
          rows="5"
          minLength={5}
          required
          placeholder={lang.cookiePolicy + "_en"}
          defaultValue={data?.cookie_policy_en}
          {...register("cookie_policy_en")}
          className="p-[10px] bg-white border border-black rounded"
        ></textarea>
        <textarea
          cols="30"
          rows="5"
          minLength={5}
          required
          placeholder={lang.refundAndCancellationPolicy + "_en"}
          defaultValue={data?.cancellation_policy_en}
          {...register("cancellation_policy_en")}
          className="p-[10px] bg-white border border-black rounded"
        ></textarea>
        <textarea
          cols="30"
          rows="5"
          minLength={5}
          required
          placeholder={lang.serviceLevelAgreement + "_en"}
          defaultValue={data?.service_level_en}
          {...register("service_level_en")}
          className="p-[10px] bg-white border border-black rounded"
        ></textarea>
        <BlueBtn text={lang.update} className={"w-[200px]"} type="  " />
      </form>
    );
}

export default BinAnalytics;
