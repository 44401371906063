import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
function DropdDown({dropText, dropContent}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdDown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button onClick={toggleDropdDown} className="flex justify-between items-center w-full">{dropText} {
        isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />
      }</button>
      {isOpen && <div>{dropContent}</div>}
    </div>
  );
}

export default DropdDown;
