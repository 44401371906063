import React, { useEffect, useState } from "react";
import DatePicker from "../ui_components/DatePicker";
import { useLang } from "../redux/selectors";
import { getRequest, postRequest } from "../utils/request";
import { useDispatch } from "react-redux";
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
function Deposit() {
  const [page, setPage] = useState(1);
  const [history, setHistory] = useState([]);
  const [filter, setFilter] = useState({});
  const dispatch = useDispatch();
  function fetchHistory(e) {
    getRequest("admin/payments/show/" + e, dispatch)
      .then((res) => {
        setHistory(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchHistory(1);
  }, []);

  const status = {
    paid: "Succes",
    system_fail: "Failed",
    reject: "Rejected",
  };
  const statusColor = {
    paid: "lime",
    system_fail: "red",
    reject: "orange",
  };
  function filterPage(e) {
    postRequest("admin/transaction/search", { ...filter, page: e }, dispatch)
      .then((res) => {
        setHistory(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function formSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {
      page: page,
    };
    formData.forEach((value, key) => {
      data[key] = value;
    });
    console.log(data);
    postRequest("admin/transaction/search", data, dispatch)
      .then((res) => {
        setHistory(res);
        setFilter(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const { lang } = useLang();
  return (
    <div className="p-[30px] px-[60px]">
      <h1 className="text-[32px] font-[500] mb-[30px]">{lang.deposit}</h1>
      <form onSubmit={formSubmit}>
        <div className="gap-4 grid grid-cols-4">
          <input
            type="text"
            className="bg-white p-[10px] border border-black rounded outline-none w-full"
            placeholder="Email"
          />
          <DatePicker
            name="created_start"
            text={lang.date_creation_from}
            required={false}
          />
          <DatePicker
            name="created_end"
            text={lang.date_creation_to}
            required={false}
          />

          <select
            name="status"
            className="bg-white p-[10px] border border-black rounded outline-none w-full"
          >
            <option value="">{lang.status}</option>
            <option value="paid">Success</option>
            <option value="system_fail">Failed</option>
            <option value="reject">Rejected</option>
          </select>
        </div>
        <div className="flex gap-4 mt-[30px]">
          <button className="bg-transparent border rounded border-[#1ABB00] p-[4px] w-[150px] text-[#1ABB00]">
            {lang.apply}
          </button>
          <button type="reset" className="bg-transparent border rounded border-[#C00000] p-[4px] w-[150px] text-[#C00000]">
            {lang.clear}
          </button>
          {/* <button className="bg-transparent border rounded border-[#F2C53A] p-[4px] w-[150px] text-[#F2C53A]">
            {lang.report}
          </button> */}
        </div>
      </form>
      <br />
      {/* <p className="text-[20px]">{lang.on_page}: 10</p> */}
      <p className="text-[20px]">
        {lang.displayed}: {history?.total_page} / {page}
      </p>

      <table className="border-collapse w-full border border-black text-center rounded-md my-[40px]">
        <tr>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            №
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            Merchant
          </td>
          {/* <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.amount}
          </td> */}
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.status}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            User
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            Crypto Amount
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.crypto_name}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.creation_date}
          </td>
          {/* <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.edition_date}
          </td> */}
        </tr>
        {history?.data?.map((item, i) => {
          return (
            <tr className={(i + 3) % 2 === 0 && "gray-row"} key={i}>
              <td className="p-[4px] border border-black bg-white">
                {item?.id}
              </td>
              <td className="p-[4px] border border-black bg-white">
                {item?.payment_provider}
              </td>
              {/* <td className="p-[4px] border border-black bg-white">
                {item.amount}$
              </td> */}
              <td
                style={{ color: statusColor[item?.status] }}
                className="p-[4px] border border-black bg-white"
              >
                {status[item.status]}
              </td>

              <td className="p-[4px] border border-black bg-white">
                {item?.email}
              </td>
              <td className="p-[4px] border border-black bg-white">
                {item?.amount}
              </td>
              <td className="p-[4px] border border-black bg-white">
                {item?.currency}
              </td>
              <td className="p-[4px] border border-black bg-white">
                {item?.created_at?.slice(0, 10)}
              </td>
              {/* <td className="p-[4px] border border-black bg-white">
                {item.lastEditDate}
              </td> */}
            </tr>
          );
        })}
      </table>
      <div className="flex justify-center gap-[10px]">
        <button
          onClick={() => {
            if (page > 1) {
              filterPage(page - 1);
              setPage(page - 1);
            }
          }}
        >
          <MdOutlineNavigateBefore className="text-[32px]" />
        </button>
        <button>
          <span className="text-[18px]">
            {history?.total_page} / {page}
          </span>
        </button>
        <button
          onClick={() => {
            if (page < history?.total_page) {
              filterPage(page + 1);
              setPage(page + 1);
            }
          }}
        >
          <MdOutlineNavigateNext className="text-[32px]" />
        </button>
      </div>
    </div>
  );
}

export default Deposit;
