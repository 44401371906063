import React from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import "react-rater/lib/react-rater.css";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { postRequest } from "../../utils/request";
import toast from "react-hot-toast";
function CreateSiteMeneger() {
  const { lang } = useLang();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  async function addFeedback(inc) {
    const result = await postRequest("admin/site_managers", inc, dispatch);
    if (result.data) {
      toast.success("Page created successfully!");
      navigate("/site-manager");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addFeedback(dataIn);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-6 w-[100%] items-start">
        <div className="p-[50px]">
          <h1 className="text-[40px]">{lang.create}</h1>
          <div className="grid gap-3 my-[40px]">
            <input
              type="text"
              required
              placeholder={`Tag title RU:`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("tag_title_ru")}
            />
            <input
              type="text"
              required
              placeholder={`Tag title En:`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("tag_title_en")}
            />
            <input
              type="text"
              required
              placeholder={`Meta keywords RU:`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("meta_keywords_ru")}
            />
            <input
              type="text"
              required
              placeholder={`Meta keywords En:`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("meta_keywords_en")}
            />
            <input
              type="text"
              required
              placeholder={`Meta description RU:`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("meta_description_ru")}
            />
            <input
              type="text"
              required
              placeholder={`Meta description En:`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("meta_description_en")}
            />
            <textarea
              required
              placeholder={`Google code HEAD:`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("google_code_head")}
              cols="30"
              rows="10"
            ></textarea>
          </div>

          <BlueBtn type="submit" text={lang.create} className={"mt-[30px]"} />
        </div>
      </div>
    </form>
  );
}

export default CreateSiteMeneger;
