import React, { useEffect, useState } from "react";
import DatePicker from "../ui_components/DatePicker";
import OutlineBtn from "../ui_components/OutlineBtn";
import BlueBtn from "../ui_components/BlueBtn";
import ChartComponent from "../ui_components/ChartComponent";
import { useLang } from "../redux/selectors";
import { getRequest } from "../utils/request";
import { useDispatch } from "react-redux";

function Dashboard() {
  const { lang } = useLang();
  const [data, setData] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getRequest(
      "admin/dashboard/all-metrics?start_date=2023-03-11&end_date=2024-09-13",
      dispatch
    )
      .then((res) => {
        setData(res.metrics);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  function filterForm(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const start_date = formData.get("from");
    const end_date = formData.get("to");
    getRequest(
      `admin/dashboard/all-metrics?start_date=${start_date}&end_date=${end_date}`,
      dispatch
    )
      .then((res) => {
        setData(res.metrics);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    data && (
      <div className="p-[30px]">
        <h1 className="text-[32px] font-[500] mb-[30px]">Dashboard</h1>
        <form onSubmit={filterForm} className="flex gap-[20px] mb-[30px]">
          <DatePicker name="from" text={"От:"} />
          <DatePicker name="to" text={"До:"} />
          {/* <OutlineBtn text={lang.today} className={"text-[20px]"} />
          <OutlineBtn text={lang.week} className={"text-[20px]"} />
          <OutlineBtn text={lang.month} className={"text-[20px]"} /> */}
          <BlueBtn text={lang.apply} className={"text-[20px]"} type="submit"/>
        </form>
        <h1 className="text-[32px] font-[500] mb-[30px]">{lang.metrics}</h1>
        <div className="grid grid-cols-2">
          <div className="border-r border-b border-black p-[15px]">
            <h1>{lang.newUsers}</h1>
            <div className="grid grid-cols-[3fr,5fr]">
              <div className="grid grid-cols-2">
                <div className="flex flex-col">
                  <h1>{lang.date}</h1>
                  {data?.newUsers?.months?.map((item, i) => (
                    <h1 key={i}>{item}</h1>
                  ))}
                </div>
                <div className="flex flex-col items-center">
                  <h1>{lang.quantity}</h1>
                  {data?.newUsers?.data?.data?.map((item, i) => (
                    <h1 key={i}>{item}</h1>
                  ))}
                </div>
              </div>
              <ChartComponent
                labels={data?.newUsers?.months}
                incom={data?.newUsers?.data}
              />
            </div>
          </div>
          <div className="border-b border-black p-[15px]">
            <h1>{lang.averageUserCheck}</h1>
            <div className="grid grid-cols-[3fr,5fr]">
              <div className="grid grid-cols-2">
                <div className="flex flex-col">
                  <h1>{lang.date}</h1>
                  {data?.averageCheck?.months?.map((item, i) => (
                    <h1 key={i}>{item}</h1>
                  ))}
                </div>
                <div className="flex flex-col items-center">
                  <h1>{lang.quantity}</h1>
                  {data?.averageCheck?.data?.data?.map((item, i) => (
                    <h1 key={i}>{item}</h1>
                  ))}
                </div>
              </div>
              <ChartComponent
                labels={data?.averageCheck?.months}
                incom={data?.averageCheck?.data}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div className="border-r border-black p-[15px]">
            <h1>{lang.usedTrafficVolume}</h1>
            <div className="grid grid-cols-[3fr,5fr]">
              <div className="grid grid-cols-2">
                <div className="flex flex-col">
                  <h1>{lang.date}</h1>
                  {data?.usedTraffic?.months?.map((item, i) => (
                    <h1 key={i}>{item}</h1>
                  ))}
                </div>
                <div className="flex flex-col items-center">
                  <h1>{lang.quantity}</h1>
                  {data?.usedTraffic?.data?.data?.map((item, i) => (
                    <h1 key={i}>{item}</h1>
                  ))}
                </div>
              </div>
              <ChartComponent
                labels={data?.usedTraffic?.months}
                incom={data?.usedTraffic?.data}
              />
            </div>
          </div>
          <div className="p-[15px]">
            <h1>{lang.soldTrafficVolume}</h1>
            <div className="grid grid-cols-[3fr,5fr]">
              <div className="grid grid-cols-2">
                <div className="flex flex-col">
                  <h1>{lang.date}</h1>
                  {data?.soldTraffic?.months?.map((item, i) => (
                    <h1 key={i}>{item}</h1>
                  ))}
                </div>
                <div className="flex flex-col items-center">
                  <h1>{lang.quantity}</h1>
                  {data?.soldTraffic?.data?.data?.map((item, i) => (
                    <h1 key={i}>{item}</h1>
                  ))}
                </div>
              </div>
              <ChartComponent
                labels={data?.soldTraffic?.months}
                incom={data?.soldTraffic?.data}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default Dashboard;
