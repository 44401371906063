import React, { useState, useEffect } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import ImageUpload from "../../ui_components/UplodaImage";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getRequest, postRequest } from "../../utils/request";
import toast from "react-hot-toast";
function UpdateTestimonials() {
  const { id } = useParams();
  const [rating, setRating] = useState(2);
  const [data, setData] = useState(false);
  const { lang } = useLang();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function getData() {
    const result = await getRequest("admin/testimonials/" + id, dispatch);
    setData(result?.data);
    setRating(result?.data?.stars);
  }
  useEffect(() => {
    getData();
// eslint-disable-next-line react-hooks/exhaustive-deps 
 }, []);
  async function addFeedback(inc) {
    let f = {
      title_ru: inc.title_ru,
      title_en: inc.title_en,
      location: inc.location,
      description_ru: inc.description_ru,
      description_en: inc.description_en,
      stars: rating,
      featured: inc.featured ? 1 : 0,
      photo: inc.photo,
    };
    const formData = new FormData();
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest("admin/testimonialUpdate/" + id, formData, dispatch);
    console.log(result);
    if (result.data) {
      toast.success("Testimonial edited successfully!");
      navigate("/testimonials");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addFeedback(dataIn);
  };
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      ...data,
    },
  });
  return (
    data && (
      <form onSubmit={handleSubmit(onSubmit)} className="p-[50px]">
        <div className="grid grid-cols-2 items-start gap-[40px]">
          <div className="grid gap-6 w-[100%] items-start">
            <input
              type="text"
              required
              placeholder={lang.title}
              className="p-[10px] bg-white border border-black rounded"
              {...register("title_ru")}
              defaultValue={data?.title_ru}
            />
            <input
              type="text"
              required
              placeholder={`${lang.title}_en`}
              className="p-[10px] bg-white border border-black rounded"
              {...register("title_en")}
              defaultValue={data?.title_en}
            />
            <input
              type="text"
              placeholder={lang.location}
              {...register("location")}
              defaultValue={data?.location}
              className="p-[10px] bg-white border border-black rounded"
            />
            <h1 className="text-[24px]">{lang.start}</h1>
            <div className="p-[10px] bg-white border border-black rounded">
              <Rater
                total={5}
                onRate={({ rating }) => setRating(rating)}
                rating={rating}
              />
            </div>
            <textarea
              placeholder={lang.content}
              className="p-[10px] bg-white border border-black rounded"
              cols="30"
              rows="10"
              {...register("description_ru")}
              defaultValue={data?.description_ru}
            ></textarea>
            <textarea
              placeholder={`${lang.content}_en`}
              className="p-[10px] bg-white border border-black rounded"
              cols="30"
              rows="10"
              {...register("description_en")}
              defaultValue={data?.description_en}
            ></textarea>
            <div className="switch-wrapper flex gap-3 mt-[10px]">
              <label className="switch">
                <input type="checkbox" {...register("featured")} />
                <span className="slider round"></span>
              </label>
              <p>{lang.featured}</p>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="border rounded-[15px] border-black bg-white overflow-hidden">
              <h1 className="p-[10px] border-b border-black text-white bg-[#038FF7]">
                {lang.testimonial_details}
              </h1>
              <div className="p-[20px] grid gap-3">
                <ImageUpload
                  name="photo"
                  control={control}
                  label={"Choose image"}
                />
                <BlueBtn
                  type="submit"
                  text={lang.publish}
                  className={"w-[200px]"}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  );
}

export default UpdateTestimonials;
