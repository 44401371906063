import React, { useState } from "react";
import BackMenu from "./cabinetTabs/BackMenu";
import HowUse from "./cabinetTabs/HowUse";
import Blog from "./cabinetTabs/Blog";
import { useLang } from "../../../redux/selectors";

function PersonalCabinet() {
  const [active, setActive] = useState(0);
  const {lang} = useLang()
  const tabs = [
    {
      id: 1,
      name: lang.sideMenu,
      component: <BackMenu />,
    },
    {
      id: 2,
      name: lang.howToUse,
      component: <HowUse />,
    },
    {
      id: 3,
      name: lang.blog,
      component: <Blog />,
    },
    
  ];
  return (
    <div>
      <div className="flex gap-4">
        {tabs.map((item, i) => (
          <button
            key={item.id}
            className={`border border-black rounded-full p-[5px] px-[10px]  text-[12px] ${
              i === active && " bg-[#038FF7] text-white "
            }`}
            onClick={() => setActive(i)}
          >
            {item.name}
          </button>
        ))}
      </div>
      <div className="mt-6">{tabs[active].component}</div>
    </div>
  );
}

export default PersonalCabinet;
