import React, { useEffect, useState } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import { LuTrash2 } from "react-icons/lu";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { deleteRequest, getRequest, postRequest } from "../../utils/request";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
function Admins() {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const [data, setData] = useState([]);
  async function getData() {
    const result = await getRequest("admin/show", dispatch);
    setData(result);
  }
  async function addItem(inc) {
    const result = await postRequest("admin/addAdmin", inc, dispatch);
    if (result.status) {
      toast.success("Admin created successfully!");
      getData();
      reset();
    } else {
      toast.error("Operation failed try again " + result.message);
    }
  }
  const onSubmit = (dataIn) => {
    addItem(dataIn);
  };
  async function deleteItem(id) {
    const result = await deleteRequest("admin/deleteAdmin/" + id, dispatch);
    if (result.status) {
      toast.success("Admin has been deleted successfully");
      getData();
    } else {
      toast.error("Operation failed try again " + result.message);
    }
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { lang } = useLang();
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-[30px] px-[60px]">
      <h1 className="text-[32px] font-[500] mb-[30px]">
        {lang.administrators}
      </h1>
      <div className="flex items-center gap-4">
        <input
          type="email"
          placeholder="E-mail:"
          className="p-[10px] bg-white border border-black rounded w-[50%]"
          {...register("email")}
          required
        />
        <input
          type="text"
          placeholder="Name:"
          className="p-[10px] bg-white border border-black rounded w-[50%]"
          {...register("name")}
        />
        <input
          type="text"
          placeholder="Password:"
          className="p-[10px] bg-white border border-black rounded w-[50%]"
          {...register("password")}
        />
        <BlueBtn text={lang.add} className={"min-w-[200px]"} type="submit"/>
      </div>

      <table className="border-collapse border border-black text-center rounded-md my-[40px] w-1/2">
        <tr>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            №
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.title}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            E-mail:
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.contorls}
          </td>
        </tr>
        {data?.map((item, i) => {
          return (
            <tr className={(i + 1) % 2 === 0 && "gray-row"} key={i}>
              <td className="p-[4px] border border-black bg-white">{i + 1}</td>
              <td className="p-[4px] border border-black bg-white">
                {item?.name}
              </td>
              <td className="p-[4px] border border-black bg-white">
                {item?.email}
              </td>
              <td className="p-[4px] border border-black bg-white">
                <button
                  className="text-[24px] text-white p-[5px] rounded bg-red-600"
                  onClick={() => {
                    window.confirm(
                      "Are you sure you want to delete this Admin?"
                    ) && deleteItem(item?.id);
                  }}
                  type="button"
                >
                  <LuTrash2 />
                </button>
              </td>
            </tr>
          );
        })}
      </table>
    </form>
  );
}

export default Admins;
