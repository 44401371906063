import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  // Your chart options
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: true,
      labels: {
        fontSize: 14, // Set the font size for legend
      },
    },

    tooltip: {
      titleFontFamily: "Arial",
      titleFontSize: 16, // Set the font size for tooltip title
      bodyFontFamily: "Arial",
      bodyFontSize: 14, // Set the font size for tooltip body
    },
  },
  maintainAspectRatio: false, // Allow the chart to be responsive
};
function ChartComponent({ labels, incom }) {
  const data = {
    labels,
    datasets: [
      // {
      //   label: "Sales",
      //   data: [1, 4, 6, 2, 4, 1, 9],
      //   borderColor: "rgb(164, 205, 60)",
      //   backgroundColor: "rgba(164, 205, 60, 0.5)",
      // }
      {
        label: "Statistics",
        ...incom,
      },
    ],
  };
  return (
    <Line options={options} data={data} width={"200px"} height={"200px"} />
  );
}

export default ChartComponent;
