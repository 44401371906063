import React, { useState, useEffect } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import "react-rater/lib/react-rater.css";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getRequest, postRequest } from "../../utils/request";
import toast from "react-hot-toast";
function UpdateUseCases() {
  const { id } = useParams();
  const [data, setData] = useState(false);
  const { lang } = useLang();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function getData() {
    const result = await getRequest("admin/use_cases/" + id, dispatch);
    setData(result?.data);
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function addFeedback(inc) {
    let f = {
      ...inc,
      icon: inc.icon[0],
    };
    const formData = new FormData();
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest(
      "admin/useCaseUpdate/" + id,
      formData,
      dispatch
    );
    console.log(result);
    if (result.data) {
      toast.success("edited successfully!");
      navigate("/use-cases");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addFeedback(dataIn);
  };
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ...data,
    },
  });
  return (
    data && (
      <form onSubmit={handleSubmit(onSubmit)} className="p-[50px]">
        <div className="grid items-start gap-[40px]">
          <h1 className="text-[32px] mb-[20px]">{lang.edit}</h1>

          <input
            type="text"
            placeholder="Title_ru:"
            className="p-[10px] bg-white border border-black rounded"
            {...register("title_ru")}
            defaultValue={data?.title_ru}
          />
          <input
            type="text"
            placeholder="Title_en:"
            className="p-[10px] bg-white border border-black rounded"
            {...register("title_en")}
            defaultValue={data?.title_en}
          />
          <input
            type="text"
            placeholder="Link:"
            className="p-[10px] bg-white border border-black rounded"
            {...register("link")}
            defaultValue={data?.link}
          />
          <div className="p-[5px] pl-[10px]  bg-white border border-black rounded flex justify-between items-center">
            <input
              type="file"
              className="relative -left-[100px]"
              id="logo"
              {...register("icon")}
            />
            <label
              htmlFor="logo"
              className="cursor-pointer p-[4px] rounded bg-[#0A9E5C] border border-black text-[#fff]"
            >
              Choose file
            </label>
          </div>
          <BlueBtn text={lang.edit} className={"w-[200px]"} type="submit" />
        </div>
      </form>
    )
  );
}

export default UpdateUseCases;
