import React, { useEffect, useState } from "react";
import BlueBtn from "../../../../ui_components/BlueBtn";
import { useLang } from "../../../../redux/selectors";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { getRequest, postRequest } from "../../../../utils/request";

function BuisnessInfo() {
  const { lang } = useLang();
  const dispatch = useDispatch();
  const [data, setData] = useState(false);
  async function getData() {
    const result = await getRequest(
      "admin/settings/social-media/show/1",
      dispatch
    );
    setData(result.data);
  }

  useEffect(() => {
    getData();
// eslint-disable-next-line react-hooks/exhaustive-deps 
 }, []);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ...data,
    },
  });
  async function updateSocial(inc) {
    console.log(inc);
    let f = {
      ...inc,
    };
    const formData = new FormData();
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest(
      "admin/settings/social-media/update/1",
      formData,
      dispatch
    );
    if (result.data) {
      toast.success("Information updated successfully!");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    updateSocial(dataIn);
  };
  return (
    data && (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid gap-6 w-[60%] items-start"
      >
        <input
          type="text"
          minLength={10}
          required
          placeholder="Youtube:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("youtube")}
          defaultValue={data?.youtube}
        />
        <input
          type="text"
          minLength={10}
          required
          placeholder="Telegram:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("telegram")}
          defaultValue={data?.telegram}
        />
        <input
          type="text"
          minLength={10}
          required
          placeholder="Vk:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("vk")}
          defaultValue={data?.vk}
        />
        <input
          type="text"
          minLength={10}
          required
          placeholder="Twitter:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("twitter")}
          defaultValue={data?.twitter}
        />

        <input
          type="text"
          minLength={10}
          required
          placeholder="Tik-Tok:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("tiktok")}
          defaultValue={data?.tiktok}
        />
        <input
          type="text"
          minLength={10}
          required
          placeholder="Facebook:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("facebook")}
          defaultValue={data?.facebook}
        />
        <input
          type="text"
          minLength={10}
          required
          placeholder="Yandex Dzen:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("yandex_dzen")}
          defaultValue={data?.yandex_dzen}
        />
        <BlueBtn text={lang.update} className={"w-[200px]"} type="  " />
      </form>
    )
  );
}

export default BuisnessInfo;
