import React, { useEffect, useState } from "react";
import BlueBtn from "../../../../ui_components/BlueBtn";
import { useLang } from "../../../../redux/selectors";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { getRequest, postRequest } from "../../../../utils/request";
import ImageUpload from "../../../../ui_components/UplodaImage";

function BuisnessInfo() {
  const { lang } = useLang();
  const dispatch = useDispatch();
  const [data, setData] = useState(false);
  async function getData() {
    const result = await getRequest(
      "admin/settings/business/show-business-information/1",
      dispatch
    );
    setData(result.data); 
  }

  useEffect(() => {
    getData();
// eslint-disable-next-line react-hooks/exhaustive-deps 
 }, []);
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      ...data,
    },
  });
  async function updateBuisness(inc) {
    let f = {
      ...inc 
    };
    const formData = new FormData();
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest(
      "admin/settings/business/update-business-information/1",
      formData,
      dispatch
    );
    if (result.data) {
      toast.success("Information updated successfully!");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    updateBuisness(dataIn);
  };
  return (
    data && (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid gap-6 w-[60%] items-start"
      >
        <input
          type="text"
          placeholder="Business Name ru:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("name_ru")}
          defaultValue={data?.name_ru}
        />
        <input
          type="text"
          placeholder="Business Name en:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("name_en")}
          defaultValue={data?.name_en}
        />
        <input
          type="email"
          placeholder="Email:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("email")}
          defaultValue={data?.email}
        />
        <input
          type="text"
          placeholder="WhatsApp:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("whatsapp")}
          defaultValue={data?.whatsapp}
        />
        <input
          type="text"
          placeholder="Telegram:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("telegram")}
          defaultValue={data?.telegram}
        />
        <input
          type="text"
          placeholder="Address ru:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("address_ru")}
          defaultValue={data?.address_ru}
        />
        <input
          type="text"
          placeholder="Address en:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("address_en")}
          defaultValue={data?.address_en}
        />
        <input
          type="text"
          placeholder="Company ru:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("company_ru")}
          defaultValue={data?.company_ru}
        />
        <input
          type="text"
          placeholder="Company en:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("company_en")}
          defaultValue={data?.company_en}
        />
        <input
          type="text"
          placeholder="Contacts ru:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("contact_ru")}
          defaultValue={data?.contact_ru}
        />
        <input
          type="text"
          placeholder="Contacts en:"
          className="p-[10px] bg-white border border-black rounded"
          {...register("contact_en")}
          defaultValue={data?.contact_en}
        />
        <ImageUpload name="file" control={control} label={"Website logo"} />
        <BlueBtn text={lang.update} className={"w-[200px]"} type="submit" />
      </form>
    )
  );
}

export default BuisnessInfo;
