import React, { useEffect, useState } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import { Link } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { deleteRequest, getRequest } from "../../utils/request";
import toast from "react-hot-toast";
function Posts() {
  const { lang, langType } = useLang();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  async function getData() {
    const result = await getRequest("admin/posts", dispatch);
    setData(result.data);
  }
  async function deleteItem(id) {
    const result = await deleteRequest("admin/posts/" + id, dispatch);
    console.log(result);
    if (result.data) {
      toast.success("Post has been deleted successfully");
      getData();
    } else {
      toast.error("Operation failed try again");
    }
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const featured = ["NO", "YES"];
  const comments = ["DISABLED", "ENABLED"];
  const status = ["DRAFT", "PUBLISHED"];
  return (
    <div className="p-[50px]">
      <h1 className="text-[32px] mb-[20px]">{lang.view_all}</h1>
      <Link to={"/add-post"}>
        <BlueBtn text={`+${lang.add}`} className={"w-[200px]"} />
      </Link>
      <div className="flex justify-between items-center mt-[20px] border-b border-b-black pb-[30px] mb-[30px]">
        <h1>Total products: {data?.length}</h1>
        <div className="bg-white rounded border border-black p-[6px] flex items-center ">
          <input
            type="text"
            placeholder="Search"
            className="bg-transparent outline-none"
          />
          <IoSearchOutline />
        </div>
      </div>
      <div className="grid grid-cols-8">
        <h1 className="text-center text-[16px] font-[500]">URL slug</h1>
        <h1 className="text-center text-[16px] font-[500]">{lang.title}</h1>
        <h1 className="text-center text-[16px] font-[500]">{lang.image}</h1>
        <h1 className="text-center text-[16px] font-[500]">{lang.featured}</h1>
        <h1 className="text-center text-[16px] font-[500]">{lang.comments}</h1>
        <h1 className="text-center text-[16px] font-[500]">{lang.status}</h1>
        <h1 className="text-center text-[16px] font-[500]">
          {lang.select_target_location}
        </h1>
        <h1 className="text-center text-[16px] font-[500]">Action</h1>
      </div>
      {data?.map((item, index) => (
        <div
          key={index}
          className={`grid grid-cols-8 gap-2 items-center p-[5px] ${
            index % 2 === 0 && " bg-white border rounded "
          }`}
        >
          <p className="text-center text-[14px] opacity-50 whitespace-break-spaces w-[120px] text-ellipsis overflow-hidden">
            {item?.url_slug}
          </p>
          <p className="text-center text-[14px] opacity-50">
            {item[`title_${langType}`]}
          </p>
          <div>
            <img
              src={"https://api.primesox.io/files/posts/photos/" + item?.photo}
              className="w-[90px] h-[70px] object-cover rounded m-auto"
              alt=""
            />
          </div>
          <p className="text-center text-[14px] opacity-50">
            {featured[Number(item?.featured)]}
          </p>
          <p className="text-center text-[14px] opacity-50">
            {comments[Number(item?.disable_comments)]}
          </p>
          <div className="m-auto">
            <button className="bg-[#038FF7] text-white border-solid border rounded-[6px] border-black text-[14px] m-auto p-[5px]">
              {status[Number(item?.status)]}
            </button>
          </div>
          <p className="text-center text-[14px] opacity-50">
            {item?.category_id}
          </p>
          <div className="flex flex-col gap-2">
            {/* <button className="bg-[#038FF7] text-white border-solid border rounded-[6px] border-black text-[14px] m-auto w-[100px]">
              {lang.view}
            </button> */}

            <Link className="text-center" to={`/update-post/${item?.id}`}>
              <button className="bg-[#0A9E5C] text-white border-solid border rounded-[6px] border-black text-[14px] m-auto w-[100px]">
                {lang.edit}
              </button>
            </Link>
            <button
              onClick={() => {
                window.confirm("Are you sure you want to delete this post?") &&
                  deleteItem(item?.id);
              }}
              className="bg-[#E80000] text-white border-solid border rounded-[6px] border-black text-[14px] m-auto w-[100px]"
            >
              {lang.delete}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Posts;
