import React, { useState } from "react";
import { Controller } from "react-hook-form";

const ImageUpload = ({ label, name = "", control }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageName, setimageName] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setimageName(file.name);
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  return (
    <div className="flex items-start gap-3 flex-col">
      {/* <input type="file" /> */}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <input
            type="file"
            accept="image/*"
            className="sr-only"
            id={name}
            onChange={(e) => {
              field.onChange(e.target.files[0]);
              handleImageChange(e);
            }}
          />
        )}
      />
      <div className="flex items-center gap-2">
        <label
          htmlFor={name}
          className="px-[10px] border border-black rounded text-white bg-[#0A9E5C]"
        >
          {label || "Choose icon"}
        </label>
        <p>
          {selectedImage ? (
            <span>{imageName} </span>
          ) : (
            <span>No file choosen </span>
          )}
        </p>
      </div>
      <label
        htmlFor={name}
        className="cursor-pointer inline-flex items-center justify-center w-full h-[200px] border border-solid border-black rounded-lg hover:border-primary focus:border-primary focus:outline-none"
      >
        {selectedImage ? (
          <img
            src={selectedImage}
            alt="Selected"
            className="object-contain w-full h-full rounded-lg"
          />
        ) : (
          <span className="text-gray-500">Select Image</span>
        )}
      </label>
    </div>
  );
};

export default ImageUpload;
