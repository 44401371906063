import React from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import { useLang } from "../../redux/selectors";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../utils/request";

function AddFaq() {
  const { lang } = useLang();
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function addFaq(inc) {
    const result = await postRequest("admin/faqs", inc, dispatch);
    if (result.data) {
      toast.success("Faq created successfully!");
      navigate("/faq");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addFaq(dataIn);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-[50px]">
      <h1 className="text-[40px]">FAQ</h1>
      <div className="w-[50%] grid gap-[20px] mt-[30px]">
        <input
          type="text"
          required
          placeholder={lang.enter_question}
          className="p-[10px] bg-white border border-black rounded"
          {...register("quession_ru")}
        />
        <input
          type="text"
          required
          placeholder={lang.enter_answer}
          className="p-[10px] bg-white border border-black rounded"
          {...register("answer_ru")}
        />
        <input
          type="text"
          required
          placeholder={`${lang.enter_question}_en`}
          className="p-[10px] bg-white border border-black rounded"
          {...register("quession_en")}
        />
        <input
          type="text"
          required
          placeholder={`${lang.enter_answer}_en`}
          className="p-[10px] bg-white border border-black rounded"
          {...register("answer_en")}
        />
        <BlueBtn text={lang.create} type="submit"/>
      </div>
    </form>
  );
}

export default AddFaq;
