
import Admins from "../pages/Admin/Admins";
import AddBlog from "../pages/Blog/AddBlog";
import Blogs from "../pages/Blog/Blogs";
import UpdateBlog from "../pages/Blog/UpdateBlog";
import AddCategory from "../pages/Category/AddCategory";
import AllCategories from "../pages/Category/AllCategories";
import UpdateCategory from "../pages/Category/UpdateCategory";
import CeoTag from "../pages/CeoTag/CeoTag";
import Clients from "../pages/Clients/Clients";
import CreateCupon from "../pages/Cupon/CreateCupon";
import Cupons from "../pages/Cupon/Cupons";
import UpdateCoupon from "../pages/Cupon/UpdateCupon";
import Dashboard from "../pages/Dashboard";
import Deposit from "../pages/Deposit";
import AddFaq from "../pages/Faq/AddFaq";
import AllFaqs from "../pages/Faq/AllFaqs";
import UpdateFaq from "../pages/Faq/UpdateFaq";
import Login from "../pages/Login";
import Orders from "../pages/Orders";
import CreatePartner from "../pages/Partners/CreatePartner";
import Partners from "../pages/Partners/Partners";
import UpdatePartner from "../pages/Partners/UpdatePartner";
import AddPost from "../pages/Posting/AddPost";
import Posts from "../pages/Posting/Posts";
import UpdatePost from "../pages/Posting/UpdatePost";
import Reseller from "../pages/Reseller/Reseller";
import UpdateReseller from "../pages/Reseller/UpdateReseller";
import Settings from "../pages/Settings/Settings";
import CreateSiteMeneger from "../pages/SiteMeneger/CreateSiteMeneger";
import SiteMenegers from "../pages/SiteMeneger/SiteMenegers";
import UpdateSiteMeneger from "../pages/SiteMeneger/UpdateSiteMeneger";
import AddSubCategory from "../pages/SubCategory/AddSubcategory";
import SubCategories from "../pages/SubCategory/SubCategories";
import UpdateSubCategory from "../pages/SubCategory/UpdateSubCategory";
import CreateTariff from "../pages/Tariff/CreateTariff";
import Tariffs from "../pages/Tariff/Tariffs";
import UpdateTariff from "../pages/Tariff/UpdateTarif";
import AddTestimonial from "../pages/Testimonial/AddTestimonial";
import AllTestimonials from "../pages/Testimonial/AllTestimonials";
import UpdateTestimonials from "../pages/Testimonial/UpdateTestimonials";
import AddUseCases from "../pages/UseCases/AddUseCases";
import AllUseCases from "../pages/UseCases/AllUseCases";
import UpdateUseCases from "../pages/UseCases/UpdateUseCases";
import Sidebar from "../ui_components/Sidebar";

export const routes = [
    {
        path: "/",
        element: <Sidebar currentPage={<Dashboard />} />,
    },
    {
        path: "/clients",
        element: <Sidebar currentPage={<Clients />} />,
    },
    {
        path: "/orders",
        element: <Sidebar currentPage={<Orders />} />,
    },
    {
        path: "/settings",
        element: <Sidebar currentPage={<Settings />} />,
    },
    {
        path: "/add-post",
        element: <Sidebar currentPage={<AddPost />} />,
    },
    {
        path: "/posts",
        element: <Sidebar currentPage={<Posts />} />,
    },
    {
        path: "/ceo-tag",
        element: <Sidebar currentPage={<CeoTag />} />,
    },
    {
        path: "/add-category",
        element: <Sidebar currentPage={<AddCategory />} />,
    },
    {
        path: "/categories",
        element: <Sidebar currentPage={<AllCategories />} />,
    },
    {
        path: "/update-category/:id",
        element: <Sidebar currentPage={<UpdateCategory />} />,
    },
    {
        path: "/update-partner/:id",
        element: <Sidebar currentPage={<UpdatePartner />} />,
    },
    {
        path: "/add-subcategory",
        element: <Sidebar currentPage={<AddSubCategory />} />,
    },
    {
        path: "/update-subcategory/:id",
        element: <Sidebar currentPage={<UpdateSubCategory />} />,
    },
    {
        path: "/subcategories",
        element: <Sidebar currentPage={<SubCategories />} />,
    },
    {
        path: "/add-comment",
        element: <Sidebar currentPage={<AddSubCategory />} />,
    },
    {
        path: "/comments",
        element: <Sidebar currentPage={<SubCategories />} />,
    },
    {
        path: "/add-testimonial",
        element: <Sidebar currentPage={<AddTestimonial />} />,
    },
    {
        path: "/testimonials",
        element: <Sidebar currentPage={<AllTestimonials />} />,
    },
    {
        path: "/update-testimonials/:id",
        element: <Sidebar currentPage={<UpdateTestimonials />} />,
    },
    {
        path: "/update-tariff/:id",
        element: <Sidebar currentPage={<UpdateTariff />} />,
    },
    {
        path: "/update-cupon/:id",
        element: <Sidebar currentPage={<UpdateCoupon />} />,
    },
    {
        path: "/deposit",
        element: <Sidebar currentPage={<Deposit />} />,
    },
    {
        path: "/tarifs",
        element: <Sidebar currentPage={<Tariffs />} />,
    },
    {
        path: "/create-tarif",
        element: <Sidebar currentPage={<CreateTariff />} />,
    },
    {
        path: "/faq",
        element: <Sidebar currentPage={<AllFaqs />} />,
    },
    {
        path: "/add-faq",
        element: <Sidebar currentPage={<AddFaq />} />,
    },
    {
        path: "/update-faq/:id",
        element: <Sidebar currentPage={<UpdateFaq />} />,
    },
    {
        path: "/update-blog/:id",
        element: <Sidebar currentPage={<UpdateBlog />} />,
    },
    {
        path: "/update-post/:id",
        element: <Sidebar currentPage={<UpdatePost />} />,
    },
    {
        path: "/update-site/:id",
        element: <Sidebar currentPage={<UpdateSiteMeneger />} />,
    },
    {
        path: "/update-case/:id",
        element: <Sidebar currentPage={<UpdateUseCases />} />,
    },
    {
        path: "/create-case",
        element: <Sidebar currentPage={<AddUseCases />} />,
    },
    {
        path: "/use-cases",
        element: <Sidebar currentPage={<AllUseCases />} />,
    },
    {
        path: "/create-site",
        element: <Sidebar currentPage={<CreateSiteMeneger />} />,
    },
    {
        path: "/site-manager",
        element: <Sidebar currentPage={<SiteMenegers />} />,
    },
    {
        path: "/blogs",
        element: <Sidebar currentPage={<Blogs />} />,
    },
    {
        path: "/add-blog",
        element: <Sidebar currentPage={<AddBlog />} />,
    },
    {
        path: "/partners",
        element: <Sidebar currentPage={<Partners />} />,
    },
    {
        path: "/create-partner",
        element: <Sidebar currentPage={<CreatePartner />} />,
    },
    {
        path: "/admins",
        element: <Sidebar currentPage={<Admins />} />,
    },
    {
        path: "/reseller",
        element: <Sidebar currentPage={<Reseller />} />,
    },
    {
        path: "/reseller/:id",
        element: <Sidebar currentPage={<UpdateReseller />} />,
    },
    {
        path: "/cupons",
        element: <Sidebar currentPage={<Cupons />} />,
    },
    {
        path: "/create-cupon",
        element: <Sidebar currentPage={<CreateCupon />} />,
    },
    {
        path: "/login",
        element: <Login />,
    },
]