import React from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import "react-rater/lib/react-rater.css";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { postRequest } from "../../utils/request";
import toast from "react-hot-toast";
function CreatePartner() {
  const { lang } = useLang();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  async function addFeedback(inc) {
    console.log(inc);
    let f = {     
      name_ru: inc.title_ru,
      name_en: inc.title_en,
      link: inc.link,
      description_ru: inc.description_ru,
      description_en: inc.description_en,
      file: inc.file[0],
    };
    const formData = new FormData();
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest("admin/partners", formData, dispatch);
    if (result.data) {
      toast.success("Partner created successfully!");
      navigate("/partners");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addFeedback(dataIn);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-[50px]">
      <div className="grid gap-6 w-[100%] items-start">
        <div className="grid gap-3 my-[40px]">
          <input
            type="text"
            required
            placeholder={lang.title}
            className="p-[10px] bg-white border border-black rounded"
            {...register("title_ru")}
          />
          <input
            type="text"
            required
            placeholder={`${lang.title}_en`}
            className="p-[10px] bg-white border border-black rounded"
            {...register("title_en")}
          />
          <input
            type="text"
            required
            placeholder={lang.paste_link}
            {...register("link")}
            className="p-[10px] bg-white border border-black rounded"
          />
          <textarea
            cols="30"
            rows="6"
            required
            placeholder={lang.description + "_ru"}
            {...register("description_ru")}
            className="p-[10px] bg-white border border-black rounded"
          ></textarea>
          <textarea
            cols="30"
            rows="6"
            required
            placeholder={lang.description + "_en"}
            {...register("description_en")}
            className="p-[10px] bg-white border border-black rounded"
          ></textarea>
          <div className="p-[10px] bg-white border border-black rounded">
            <label
              htmlFor="file"
              className="cursor-pointer flex justify-between items-center"
            >
              <input
                type="file"
                id="file"
                className="hidden"
                required
                placeholder="Вставить ответ"
                {...register("file")}
              />
              <span>{lang.select_file}</span>
              <span className="bg-[#038FF7] text-[#fff] p-[10px] px-[15px] rounded border border-black">
                {lang.upload}
              </span>
            </label>
          </div>
          <BlueBtn type="submit" text={lang.add} className={"w-[200px]"} />
        </div>
      </div>
    </form>
  );
}

export default CreatePartner;
