import React, { useEffect, useState } from "react";
import DatePicker from "../ui_components/DatePicker";
import { LuRefreshCcw } from "react-icons/lu";
import { useLang } from "../redux/selectors";
import { getRequest, postRequest } from "../utils/request";
import { useDispatch } from "react-redux";
function Orders() {
  const [tableInfo, setTableInfo] = useState([]);
  const [pageNation, setPageNation] = useState(10);
  const dispatch = useDispatch();
  useEffect(() => {
    getRequest("admin/order/all", dispatch)
      .then((res) => {
        setTableInfo(res?.data?.orders);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const { lang } = useLang();

  function formSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    console.log(data);
    postRequest("admin/order/search", data, dispatch)
      .then((res) => {
        setTableInfo(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className="p-[30px] px-[60px]">
      <h1 className="text-[32px] font-[500] mb-[30px]">{lang.orders}</h1>
      <form onSubmit={formSubmit}>
        <div className="gap-4 grid grid-cols-3">
          <input
            type="text"
            className="bg-white p-[10px] border border-black rounded outline-none w-full"
            placeholder="Email"
            name="email"
          />
          <DatePicker
            name="created_start"
            required={false}
            text={lang.Create_package}
          />
          <DatePicker
            name="created_end"
            required={false}
            text={lang.Creating_before}
          />
          {/* <DatePicker
            name="expiry_start"
            required={false}
            text={lang.Package_from}
          />
          <DatePicker
            name="expiry_end"
            required={false}
            text={lang.Package_date}
          /> */}
        </div>
        <div className="flex gap-4 mt-[30px]">
          <button
            type="submit"
            className="bg-transparent border rounded border-[#1ABB00] p-[4px] w-[150px] text-[#1ABB00]"
          >
            {lang.apply}
          </button>
          <button
            type="reset"
            className="bg-transparent border rounded border-[#C00000] p-[4px] w-[150px] text-[#C00000]"
          >
            {lang.clear}
          </button>

          {/* <button
            type="button"
            className="bg-transparent border rounded border-[#F2C53A] p-[4px] w-[150px] text-[#F2C53A]"
          >
            {lang.report}
          </button> */}
        </div>
      </form>
      <br />
      <p className="text-[20px]">
        {lang.on_page}: {pageNation}
      </p>
      <p className="text-[20px]">
        {lang.displayed}: {pageNation} / {tableInfo.length}
      </p>

      <table className="border-collapse w-full border border-black text-center rounded-md my-[40px]">
        <tr>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            №
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            User
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.package}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.status}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.traffic}(GB)
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.tariff}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.creation}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.exprition}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.deact}
          </td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.restore}
          </td>
        </tr>
        <tr>
          <td
            className="p-[4px] bg-[#038FF7] border border-black text-white"
            colSpan={4}
          ></td>
          <td className="p-[4px] bg-[#038FF7] border border-black text-white">
            {lang.all}: 12126GB
          </td>
          <td
            className="p-[4px] bg-[#038FF7] border border-black text-white"
            colSpan={5}
          ></td>
        </tr>
        {tableInfo?.slice(0, pageNation)?.map((item, i) => {
          return (
            <tr className={(i + 3) % 2 === 0 && "gray-row"} key={i}>
              <td className="p-[4px] border border-black">{i + 1}</td>
              <td className="p-[4px] border border-black">
                {item.user_email || item.email}
              </td>
              <td className="p-[4px] border border-black">{item.package}</td>
              <td className="p-[4px] border border-black">{item.status}</td>
              <td className="p-[4px] border border-black w-[160px]">
                <div className="flex flex-col items-center">
                  <p>{item.traffic}</p>
                  <button className="bg-[#0A9E5C] border border-black rounded text-[#fff] w-[150px]">
                    Добавить трафик
                  </button>
                </div>
              </td>
              <td className="p-[4px] border border-black">{item.name_ru}</td>
              <td className="p-[4px] border border-black">
                {item.created_at.slice(0, 10)}
              </td>
              <td className="p-[4px] border border-black">
                {item.expire_date}
              </td>
              <td className="p-[4px] border border-black">
                <button className="text-[24px]">&times;</button>
              </td>
              <td className="p-[4px] border border-black">
                <button>
                  <LuRefreshCcw />
                </button>
              </td>
            </tr>
          );
        })}
      </table>
      {pageNation < tableInfo.length && (
        <button
          onClick={() => {
            setPageNation(pageNation + 10);
          }}
          className="bg-[#0A9E5C] border border-black p-[10px] rounded text-[#fff] w-[150px]"
        >
          Показать еще
        </button>
      )}
    </div>
  );
}
export default Orders;
