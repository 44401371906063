import React, { useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "../../ui_components/DatePicker";
import { postRequest } from "../../utils/request";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

export default function EditProfile({ OnEdit, closeModal, getData }) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  async function updatePassword(data) {
    const result = await postRequest(
      "admin/client/update-password-client/" + OnEdit.id,
      data,
      dispatch
    );
    console.log(result);
    if (result.data) {
      toast.success("Password has been updated successfully");
      getData();
      closeModal();

    } else {
      toast.error("failed try again");
    }
  }
  async function updateClient(data) {
    const result = await postRequest(
      "admin/client/update-client/" + OnEdit.id,
      data,
      dispatch
    );
    console.log(result);
    if (result.data) {
      toast.success("Client has been updated successfully");
      getData();
      closeModal();

    } else {
      toast.error("failed try again");
    }
  }
  const onSubmit = (data) => updateClient(data);

  const onSubmitPassword = () => {
    let data = {
      password: password,
      confirm_password: confirmPassword,
    };
    if (password === confirmPassword) {
      updatePassword(data);
    } else {
      toast.error("Passwords are not same!");
    }
  };
  async function blockAccount(e) {
    const result = await postRequest(
      "admin/client/block-account/" + OnEdit.id,
      { block_account: e },
      dispatch
    );
    console.log(result);
    if (result.data) {
      toast.success("Client has been changed successfully");
      getData();
      closeModal();
    } else {
      toast.error("failed try again");
    }
  }
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-[99]">
      <div className="bg-white p-[30px] rounded">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between">
            <h1 className="text-[24px] font-[500] mb-[30px]">
              {/* Редактировать профиль */}
            </h1>
            <h1 className="text-[24px] font-[500] mb-[30px]">
              <button className="cursor-pointer" onClick={() => closeModal()}>
                &times;
              </button>
            </h1>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <input
              type="text"
              className="bg-white p-[10px] border border-black rounded outline-none min-w-[290px]"
              placeholder="Имя:"
              defaultValue={OnEdit?.name}
              {...register("name")}
            />
            <input
              type="text"
              className="bg-white p-[10px] border border-black rounded outline-none min-w-[290px]"
              placeholder="Фамилия:"
              defaultValue={OnEdit?.surname}
              {...register("surname")}
            />
            <input
              type="text"
              className="bg-white p-[10px] border border-black rounded outline-none min-w-[290px]"
              placeholder="Telegram"
              defaultValue={OnEdit?.telegram}
              {...register("telegram")}
            />
            <input
              type="text"
              className="bg-white p-[10px] border border-black rounded outline-none min-w-[290px]"
              placeholder="Ref. %:"
              defaultValue={OnEdit?.refs}
              {...register("refs")}
            />

            <input
              type="email"
              className="bg-white p-[10px] border border-black rounded outline-none min-w-[290px]"
              placeholder="E-mail:"
              defaultValue={OnEdit?.email}
              {...register("email")}
            />
            <input
              type="text"
              className="bg-white p-[10px] border border-black rounded outline-none min-w-[290px]"
              placeholder="Баланс $:"
              defaultValue={OnEdit?.balance}
              {...register("balance")}
            />
            <select
              className="bg-white p-[10px] border border-black rounded outline-none min-w-[290px]"
              defaultValue={OnEdit?.type}
              {...register("type")}
            >
              <option value="">Тип:</option>
              <option value="client">Client</option>
            </select>

            <select
              className="bg-white p-[10px] border border-black rounded outline-none min-w-[290px]"
              defaultValue={OnEdit?.gender}
              {...register("gender")}
            >
              <option value="">Пол:</option>
              <option value="1">Мужской</option>
              <option value="0">Женский</option>
            </select>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <DatePicker
              text="Дата создания:"
              defaultValue={OnEdit.created_at}
            />
            <DatePicker
              text="Дата редактирования:"
              defaultValue={OnEdit.updated_at}
            />

            <button
              className={
                "w-[50%] p-[10px] rounded bg-blue-500 border border-solid text-white"
              }
              type="submit"
            >
              Сохранить изменения
            </button>
          </div>
        </form>
        <h1 className="text-[24px] font-[500] mb-[30px] mt-[30px]">
          Изменить пароль
        </h1>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <input
            type="text"
            className="bg-white p-[10px] border border-black rounded outline-none min-w-[290px]"
            placeholder="Пароль:"
            onKeyUp={(e) => setPassword(e.target.value)}
          />
          <input
            type="text"
            className="bg-white p-[10px] border border-black rounded outline-none min-w-[290px]"
            placeholder="Подтвердить пароль:"
            onKeyUp={(e) => setConfirmPassword(e.target.value)}
          />
          <button
            className={
              "w-[50%] p-[10px] rounded bg-blue-500 border border-solid text-white"
            }
            onClick={onSubmitPassword}
          >
            Сохранить изменения
          </button>
        </div>
        <h1 className="text-[24px] font-[500] mb-[30px] mt-[30px]">
          Блокировка
        </h1>
        {OnEdit?.block_account == 0 ? (
          <button
            className="bg-red-600 text-white p-[10px] border border-black rounded"
            onClick={() => blockAccount(1)}
          >
            Заблокировать профиль
          </button>
        ) : (
          <button
            className="bg-green-600 text-white p-[10px] border border-black rounded"
            onClick={() => blockAccount(0)}
          >
            Разблокировать
          </button>
        )}
      </div>
    </div>
  );
}
