// import React from "react";
// import BlueBtn from "../../../../ui_components/BlueBtn";
// import { useLang } from "../../../../redux/selectors";

// function Seo() {
//   const { lang } = useLang();
//   return (
//     <div className="grid gap-6 w-[60%] items-start">

//       <BlueBtn text={lang.update} className={"w-[200px]"} />
//     </div>
//   );
// }

// export default Seo;

// import React from "react";
// import BlueBtn from "../../../../ui_components/BlueBtn";
// import { useLang } from "../../../../redux/selectors";

// function Legal() {
//   const {lang} = useLang()
//   return (
//     <div className="grid gap-6 w-[60%] items-start">

//       <BlueBtn text={lang.update} className={"w-[200px]"} />
//     </div>
//   );
// }

// export default Legal;

import React, { useEffect, useState } from "react";
import BlueBtn from "../../../../ui_components/BlueBtn";
import { useLang } from "../../../../redux/selectors";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { getRequest, postRequest } from "../../../../utils/request";

function BinAnalytics() {
  const { lang } = useLang();
  const dispatch = useDispatch();
  const [data, setData] = useState(false);
  async function getData() {
    const result = await getRequest("admin/settings/seo/show/1", dispatch);
    setData(result?.data);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ...data,
    },
  });
  async function updateDocument(inc) {
    const result = await postRequest(
      "admin/settings/seo/update/1",
      inc,
      dispatch
    );
    if (result.status) {
      toast.success("Documents updated successfully!");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    updateDocument(dataIn);
  };
  return (
    data && (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid gap-6 w-[60%] items-start"
      > 
        <input
          type="text"
          placeholder={lang.site_title + "_ru"}
          defaultValue={data.title_ru}
          {...register("title_ru")}
          className="p-[10px] bg-white border border-black rounded"
        />
        <input
          type="text"
          placeholder={lang.site_title + "_en"}
          defaultValue={data.title_en}
          {...register("title_en")}
          className="p-[10px] bg-white border border-black rounded"
        />
        <textarea
          name=""
          id=""
          cols="30"
          rows="6"
          placeholder={lang.keywords}
          defaultValue={data.keywords}
          {...register("keywords")}
          className="p-[10px] bg-white border border-black rounded"
        ></textarea>
        <textarea
          cols="30"
          rows="6"
          placeholder={lang.site_desc + "_ru"}
          defaultValue={data.description_ru}
          {...register("description_ru")}
          className="p-[10px] bg-white border border-black rounded"
        ></textarea>
        <textarea
          cols="30"
          rows="6"
          placeholder={lang.site_desc + "_en"}
          defaultValue={data.description_en}
          {...register("description_en")}
          className="p-[10px] bg-white border border-black rounded"
        ></textarea>
        <BlueBtn text={lang.update} className={"w-[200px]"} type="submit" />
      </form>
    )
  );
}

export default BinAnalytics;
