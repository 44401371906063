import React from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import { useLang } from "../../redux/selectors";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../utils/request";

function CreateTariff() {
  const { lang } = useLang();
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function addTariff(inc) {
    const result = await postRequest("admin/plans", inc, dispatch);
    if (result.data) {
      toast.success("Tariff created successfully!");
      navigate("/tarifs");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addTariff(dataIn);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-[50px]">
      <h1 className="text-[40px]">{lang.create}</h1>
      <div className="w-[50%] grid gap-[20px] mt-[30px]">
        <div className="grid gap-3 my-[40px]">
          <input
            type="text"
            placeholder={`${lang.tariff_name}_ru`}
            className="p-[10px] bg-white border border-black rounded"
            {...register("name_ru")}
          />
          <input
            type="text"
            placeholder={`${lang.tariff_name}_en`}
            className="p-[10px] bg-white border border-black rounded"
            {...register("name_en")}
          />
          <input
            type="text"
            placeholder={`${lang.price} ($) month:`}
            className="p-[10px] bg-white border border-black rounded"
            {...register("price_month")}
          />
          <input
            type="text"
            placeholder={`${lang.price} ($) traffic:`}
            className="p-[10px] bg-white border border-black rounded"
            {...register("price_traffic")}
          />
          <input
            type="text"
            placeholder={`${lang.traffic} (GB):`}
            className="p-[10px] bg-white border border-black rounded"
            {...register("traffic")}
          />
          <input
            type="text"
            placeholder="Порты:"
            className="p-[10px] bg-white border border-black rounded"
            {...register("port")}
          />
          <input
            type="text"
            placeholder="Цвет рамки:"
            className="p-[10px] bg-white border border-black rounded"
            {...register("color_frame")}
          />
          <select
            placeholder="Цвет рамки:"
            className="p-[10px] bg-white border border-black rounded"
            {...register("type")}
          >
            <option value="dinamic">Динамический</option>
            <option value="static">Статический</option>
            <option value="mobile">Мобильный</option>
          </select>
        </div>

        {/* <div className="grid grid-cols-[2fr,3fr]">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              className="w-[23px] h-[23px] border border-black"
              {...register("show")}
            />
            {lang.show}:
          </div>
          <p>Santiagoalinovi1@gmail.com</p>
        </div> */}
        <BlueBtn text={lang.create} type="submit" />
      </div>
    </form>
  );
}

export default CreateTariff;
