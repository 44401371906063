import React, { useEffect, useState } from "react";
import BlueBtn from "../../ui_components/BlueBtn";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageUpload from "../../ui_components/UplodaImage";
import { useLang } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getRequest, postRequest } from "../../utils/request";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
function AddPost() {
  const [value, setValue] = useState("");
  const [valueEn, setValueEn] = useState("");
  const [type, setType] = useState("");
  const [slugValue, setSlugValue] = useState("");
  const [category, setCategory] = useState([]);
  async function getCategory() {
    const result = await getRequest("admin/sub_categories", dispatch);
    setCategory(result.data);
  }
  useEffect(() => {
    getCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang } = useLang();
  const { register, handleSubmit, control } = useForm();
  function inputChange(e) {
    let val = e.target.value;
    setSlugValue(
      `https://primesox.io/posts/${val.replaceAll(" ", "-").toLowerCase()}`
    );
  }
  async function addPost(inc) {
    let ru = value;
    let en = valueEn;
    console.log(inc);
    const f = {
      ...inc,
      description_ru: ru,
      description_en: en,
      url_slug: slugValue,
      disable_comments: inc.disable_comments ? "1" : "0",
      featured: inc.featured ? "1" : "0",
      photo: inc.photo,
      icon: inc.icon,
    };
    const formData = new FormData();
    Object.keys(f).forEach((key) => {
      formData.append(key, f[key]);
    });
    const result = await postRequest("admin/posts", formData, dispatch);
    console.log(result);
    if (result.data) {
      toast.success("Post created successfully!");
      navigate("/posts");
    } else {
      toast.error("Operation failed try again");
    }
  }
  const onSubmit = (dataIn) => {
    addPost(dataIn);
  };
  return (
    <div className="p-[50px]">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-2 items-start gap-[40px]"
      >
        <div className="grid gap-6 w-[100%] items-start">
          <h1 className="text-[24px]">{lang.add}</h1>
          <input
            required
            type="text"
            placeholder={lang.title + "_ru"}
            className="p-[10px] bg-white border border-black rounded"
            {...register("title_ru")}
          />
          <input
            required
            type="text"
            placeholder={lang.title + "_en"}
            className="p-[10px] bg-white border border-black rounded"
            {...register("title_en")}
          />
          <input
            type="text"
            placeholder="Slug:"
            {...register("slug")}
            onKeyUp={inputChange}
            className="p-[10px] bg-white border border-black rounded"
          />
          <ReactQuill
            theme="snow"
            placeholder="Description_ru"
            value={value}
            onChange={(e) => setValue(e)}
            className="bg-white  min-h-[300px]"
          />
          <ReactQuill
            theme="snow"
            placeholder="Description_en"
            value={valueEn}
            onChange={(e) => setValueEn(e)}
            className="bg-white  min-h-[300px]"
          />
          <textarea
            name=""
            placeholder={`${lang.except}_ru`}
            className="p-[10px] bg-white border border-black rounded"
            id=""
            cols="30"
            rows="10"
            {...register("except_ru")}
          ></textarea>
          <textarea
            name=""
            placeholder={`${lang.except}_en`}
            className="p-[10px] bg-white border border-black rounded"
            id=""
            cols="30"
            rows="10"
            {...register("except_en")}
          ></textarea>
          <h1 className="text-[24px]">SEO search midnight details</h1>
          <input
            required
            type="text"
            placeholder={lang.seo_title}
            className="p-[10px] bg-white border border-black rounded"
            {...register("seo_title")}
          />
          <input
            required
            type="text"
            placeholder={lang.seo_description}
            className="p-[10px] bg-white border border-black rounded"
            {...register("seo_description")}
          />
          <input
            required
            type="text"
            placeholder={lang.seo_keywords}
            className="p-[10px] bg-white border border-black rounded"
            {...register("seo_keywords")}
          />
          <input
            required
            type="text"
            placeholder={lang.seo_tag}
            className="p-[10px] bg-white border border-black rounded"
            {...register("seo_tag")}
          />
          <BlueBtn text={lang.add} type="submit" className={"w-[200px]"} />
        </div>
        <div className="flex flex-col gap-3">
          <div className="border rounded-[15px] border-black bg-white overflow-hidden">
            <h1 className="p-[10px] border-b border-black text-white bg-[#038FF7]">
              {lang.post_details}
            </h1>
            <div className="p-[20px] grid gap-3">
              <input
                type="text"
                placeholder="URL slug:"
                value={slugValue}
                className="p-[5px] rounded outline-none border border-black"
              />
              <label htmlFor="" className="flex flex-col">
                Select type
                <select
                  onChange={(e) => setType(e.target.value)}
                  className="p-[5px] rounded outline-none border border-black cursor-pointer"
                >
                  <option value="">Select type</option>
                  <option value="0">Posting to pages</option>
                  <option value="1">Posting to Subcategories</option>
                </select>
              </label>
              {type === "1" ? (
                <label htmlFor="" className="flex flex-col">
                  {lang.select_target_location}
                  <select
                    {...register("sub_category_id")}
                    className="p-[5px] rounded outline-none border border-black cursor-pointer"
                  >
                    {category?.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item?.name_en}
                      </option>
                    ))}
                  </select>
                </label>
              ) : type === "0" ? (
                <label htmlFor="" className="flex flex-col">
                  {lang.select_target_location}
                  <select
                    {...register("target_location")}
                    className="p-[5px] rounded outline-none border border-black cursor-pointer"
                  >
                    <option value="New page">New page</option>
                    <option value="Helpful">Helpful</option>
                    <option value="Country">Country</option>
                    <option value="Popular use cases">Popular use cases</option>
                  </select>
                </label>
              ) : null} 
              <label htmlFor="" className="flex flex-col">
                {lang.status}
                <select
                  {...register("status")}
                  className="p-[5px] rounded outline-none border border-black cursor-pointer"
                >
                  <option value="0">Draft</option>
                  <option value="1">Published</option>
                </select>
              </label>

              <div className="switch-wrapper flex gap-3 mt-[20px]">
                <label className="switch">
                  <input type="checkbox" {...register("disable_comments")} />
                  <span className="slider round"></span>
                </label>
                <p>Disable comments</p>
              </div>
              <div className="switch-wrapper flex gap-3 mt-[10px]">
                <label className="switch">
                  <input type="checkbox" {...register("featured")} />
                  <span className="slider round"></span>
                </label>
                <p>featured</p>
              </div>
            </div>
          </div>
          <div className="border rounded-[15px] border-black bg-white overflow-hidden">
            <h1 className="p-[10px] border-b border-black text-white bg-[#038FF7]">
              {lang.testimonial_details}
            </h1>
            <div className="p-[20px] grid gap-3">
              <input
                required
                type="text"
                placeholder="How to use:"
                className="p-[5px] rounded outline-none border border-black"
                {...register("how_to_use")}
              />
              <ImageUpload
                label={"Choose image"}
                name="photo"
                control={control}
              />
              <ImageUpload name="icon" control={control} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddPost;
