// import React from "react";
// import BlueBtn from "../../../../ui_components/BlueBtn";
// import { useLang } from "../../../../redux/selectors";

// function Menu() {
//   const { lang } = useLang();
//   return (
//     <div className="grid grid-cols-2 items-start gap-[40px]">
//       <div className="grid gap-6 w-[100%] items-start">
//         <h1 className="text-[24px]">{lang.menu}</h1>
//         <h1 className="text-[18px]">{lang.add}</h1>
//         <input
//           type="text"
//           placeholder={lang.name_menu + "_ru"}
//           className="p-[10px] bg-white border border-black rounded"
//         />
//         <input
//           type="text"
//           placeholder={lang.name_menu + "_en"}
//           className="p-[10px] bg-white border border-black rounded"
//         />
//         <input
//           type="text"
//           placeholder={lang.link_menu}
//           className="p-[10px] bg-white border border-black rounded"
//         />
//         <BlueBtn text={lang.create} className={"w-[200px]"} />
//       </div>
//       <div className="border rounded-[15px] border-black bg-white">
//         <h1 className="p-[10px] border-b border-black">{lang.all_menus}</h1>
//         <div className="p-[10px]">
//           <table className="w-[100%] text-center">
//             <tr>
//               <td className="text-[18px] pb-[10px] font-semibold">
//                 {lang.title}
//               </td>
//               <td className="text-[18px] pb-[10px] font-semibold">
//                 {lang.post_count}
//               </td>
//               <td className="text-[18px] pb-[10px] font-semibold">
//                 {lang.action}
//               </td>
//             </tr>
//             <tr>
//               <td>News</td>
//               <td>0</td>
//               <td>
//                 <button className=" p-[3px] px-[10px] bg-[#0A9E5C] mr-2 rounded text-white text-[12px] border border-black">
//                   {lang.edit}
//                 </button>

//                 <button className=" p-[3px] px-[10px] bg-red-500 rounded text-white text-[12px] border border-black">
//                   {lang.delete}
//                 </button>
//               </td>
//             </tr>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Menu;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getRequest,
  postRequest,
} from "../../../../utils/request";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import BlueBtn from "../../../../ui_components/BlueBtn";
import { useLang } from "../../../../redux/selectors";

function Menu() {
  const dispatch = useDispatch();
  const { lang, langType } = useLang();
  const [data, setData] = useState([]);
  const [onEdit, setOnEdit] = useState(false);
  async function getData() {
    const result = await getRequest("admin/settings/menu/show", dispatch);
    setData(result.data);
    console.log(result.data, "----------->");
  }
  async function deleteTag(id) {
    const result = await postRequest("admin/settings/menu/delete/" + id, {}, dispatch);
    console.log(result);
    if (result.status) {
      toast.success("Tag has been deleted successfully");
      getData();
    } else {
      toast.error("Operation failed try again");
    }
  }
  async function createTag(data) {
    const result = await postRequest(
      "admin/settings/menu/update",
      data,
      dispatch
    );
    console.log(result);
    if (result.status) {
      toast.success("Tag has been created successfully");
      getData();
    } else {
      toast.error("Operation failed try again");
    }
  }

  async function updateTag(data) {
    const result = await postRequest(
      "admin/settings/menu/update/" + onEdit?.id,
      data,
      dispatch
    );
    console.log(result);
    if (result.data) {
      toast.success("Tag has been updated successfully");
      getData();
    } else {
      toast.error(result.message);
    }
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name_ru: onEdit?.name_ru,
      name_en: onEdit?.name_en,
      link: onEdit?.link,
    },
  });

  const onSubmit = (data) => {
    if (onEdit) {
      updateTag(data);
      setOnEdit(false);
    } else {
      createTag(data);
    }
    reset();
  };
  return (
    <div className="grid grid-cols-2 items-start gap-[40px] p-[50px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-6 w-[100%] items-start">
          <h1 className="text-[24px]">{lang.menu}</h1>
          <h1 className="text-[18px]">{onEdit ? lang.edit : lang.add}</h1>
          <input
            type="text"
            placeholder={lang.title + "_ru"}
            defaultValue={onEdit?.name_ru}
            {...register("name_ru")}
            className="p-[10px] bg-white border border-black rounded"
          />
          <input
            type="text"
            placeholder={lang.title + "_en"}
            defaultValue={onEdit?.name_en}
            {...register("name_en")}
            className="p-[10px] bg-white border border-black rounded"
          />
          <input
            type="text"
            placeholder="Link:"
            defaultValue={onEdit?.link}
            {...register("link")}
            className="p-[10px] bg-white border border-black rounded"
          />
          <BlueBtn
            text={onEdit ? lang.edit : lang.create}
            className={"w-[150px]"}
            type="submit"
          />
        </div>
      </form>

      <div className="border rounded-[15px] border-black bg-white">
        <h1 className="p-[10px] border-b border-black">{lang.all_menus}</h1>
        <div className="p-[10px]">
          <table className="w-[100%] text-center">
            <tr>
              <td className="text-[18px] pb-[10px] font-semibold">
                {lang.title}
              </td>
              {/* <td className="text-[18px] pb-[10px] font-semibold">
                {lang.quantity}
              </td> */}
              <td className="text-[18px] pb-[10px] font-semibold">
                {lang.action}
              </td>
            </tr>
            {data?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item[`name_${langType}`]}</td>
                  {/* <td>{item?.quantity}</td> */}
                  <td className="py-[3px]">
                    <button
                      onClick={() => setOnEdit(item)}
                      className=" p-[3px] px-[10px] bg-[#0A9E5C] mr-2 rounded text-white text-[12px] border border-black"
                    >
                      {lang.edit}
                    </button>

                    <button
                      onClick={() => deleteTag(item?.id)}
                      className=" p-[3px] px-[10px] bg-red-500 rounded text-white text-[12px] border border-black"
                    >
                      {lang.delete}
                    </button>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
}

export default Menu;
