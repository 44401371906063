import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    isLoader: false,
}

export const assistantsSlice = createSlice({
    name: 'assistants',
    initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoader = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { loaderChange } = assistantsSlice.actions

export default assistantsSlice.reducer